import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import "./searchMajors.scss";

function SearchMajors(props) {
  const { featured } = props;
  const [active, setActive] = useState(false);
  const handleFocus = () => {
    if (!active) {
      setActive(true);
    }
  };
  const handleOnBlur = () => {
    setActive(false);
  };
  return (
    //     <span className="container-outer-search-profile">
    <span className="container-search-major">
      <span className="container-inner-search-profile">
        <FaSearch className="btn-search-profile" />
        <input
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          className="input-search-hiring"
          type="text"
          placeholder="Search major by category 🛠 💻 🧠"
        />

        <span
          className={
            active
              ? "container-outer-skills skills-dropdown-menu"
              : "container-outer-skills"
          }
        >
          <ul>
            {featured.map((major) => {
              return <li key={major.id}>{major.major}</li>;
            })}
            <Link
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid blue",
                padding: "2px",
                width: "fit-content",
                margin: "15px auto",
              }}
            >
              <IoIosAdd style={{ display: "block", fontSize: "25px" }} />
              <span>Add New Major</span>
            </Link>
          </ul>
        </span>
      </span>
    </span>
  );
}

export default SearchMajors;
