import React from "react";
import uuid from "react-uuid";
import ReactGA from "react-ga4";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Error from "./pages/Error";
import AddReview from "./pages/AddReview";
import AllReviews from "./pages/AllReviews";

import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
export const blogContext = React.createContext();

const routes = [
  {
    path: "/",
    component: Home,
    id: uuid(),
  },
  {
    path: "/team",
    component: Team,
    id: uuid(),
  },
  {
    path: "/reviews/add-new-review",
    component: AddReview,
    id: uuid(),
  },
  {
    path: "/allreviews/:slug",
    component: AllReviews,
    id: uuid(),
  },
];

function App() {
  const { user } = useAuth0();
  const TRACKING_ID = "G-1V5LNSLDX4";
  ReactGA.initialize(TRACKING_ID);
  return (
    <>
      <blogContext.Provider value={{}}>
        <Switch>
          {routes.map((route) => {
            const { id, component, path } = route;
            return <Route key={id} exact path={path} component={component} />;
          })}
          <Route component={Error} />
        </Switch>
      </blogContext.Provider>
    </>
  );
}

export default App;
