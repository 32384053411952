import React from "react";
//import loadingGif from "../../assets/loading/loading-gear.gif";
import loadingGif from "../../assets/loading/loading-arrow.gif";

import "./loading.scss";

export default function Loading({ title, subtitle }) {
  return (
    <div className="loading">
      <h4> {title}</h4>
      <img src={loadingGif} alt="loading spinner" />
      <h4>{subtitle}</h4>
    </div>
  );
}
