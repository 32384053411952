import React from "react";

const Subscribe = () => {
  return (
    <div className="footer-product-links">
      <h4>Our Products</h4>
      <p>
        <a href="https://www.devhackers.com/products">
          Explore and find relevant products
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/projects">
          Sign up a course to learn how to code
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/signup">
          Leave a review for other HackersDev
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/contributors">
          Become a contributors
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/tutorials">
          Access our tech tutorials
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/mentoring">Find a mentor</a>
      </p>
      <p>
        <a href="https://www.devhackers.com/interviews">
          Access our interview questions resources
        </a>
      </p>
      <p>
        <a href="https://www.devhackers.com/blogs">
          Access our free latest free blogs
        </a>
      </p>
    </div>
  );
};

export default Subscribe;
