import React from "react";
import { useHistory } from "react-router-dom";

const Authentification = ({ visible, logout, user, isAuthenticated }) => {
  const history = useHistory();

  const handleLogout = () => {
    const currentUrl = window.location.pathname + window.location.search; // Capture current path and query params
    console.log(`this is the currentUrl ${currentUrl}`);
    localStorage.setItem("lastVisitedUrl", currentUrl);
    logout();
    history.push(currentUrl); // Redirect to the current page after logout
    window.location.reload(); // Reload the page to reflect the logout state
  };
  return (
    <div
      className={
        visible
          ? " visible_container container_information_user_products "
          : "unvisible_container container_information_user_products"
      }
    >
      <span title="close button" className="close-btn">
        x
      </span>
      <div className="container_information_user_products_row1">
        <span>Welcome,</span>

        {isAuthenticated && <span> {user.given_name || user.nickname}</span>}
        <div className="nav-header">
          <a href="/" className="container-logo">
            <div className="container-logo-symbol">
              <span>RM</span>
            </div>
            <div className="container-logo-title">
              <span>RateMyMajor</span>
            </div>
          </a>
        </div>
      </div>

      <p className="container_information_user_products_row2">
        The ultimate major exploration platform to help students pick the right
        major based on real-life experiences shared by others in school and in
        the workforceleading to better career choices.
        <br />
        🚀
      </p>

      <button className="btn-primary" onClick={handleLogout}>
        logout
      </button>
    </div>
  );
};

export default Authentification;
