import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter as Router } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

// import * as serviceWorker from "./serviceWorker";
import Auth0ProviderWithHistory from "./Auth0ProviderWithHistory";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Auth0ProviderWithHistory />
    </BrowserRouter>
  </HelmetProvider>,

  document.getElementById("root")
);
// serviceWorker.unregister();
