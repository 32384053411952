import React from "react";

import facebook from "../../assets/companyLogo/facebook.svg";
import google from "../../assets/companyLogo/google.svg";
import microsoft from "../../assets/companyLogo/microsoft.svg";
import youtube from "../../assets/companyLogo/youtube.svg";
import bloomberg from "../../assets/companyLogo/bloomberg.svg";
import zillow from "../../assets/companyLogo/zillow.svg";
import "./companyLogo.scss";

function CompanyLogo() {
  return (
    <div className="company-logo-articles">
      <div className="container-company-logo-article">
        <div>
          <div className="company-logo-articles-side company-logo-articles-side1"></div>
          <div className="company-logo-articles-side company-logo-articles-side2"></div>
          <div className="company-logo-articles-container">
            <div className=" company-logo-articles-container-logo company-logo-articles-container-logo1-companies">
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646684502/amazon_aa3184bfa1.webp"
                  className="opacity"
                  alt="amazon logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={facebook}
                  className="opacity"
                  alt="facebook logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={google}
                  className="opacity"
                  alt="google logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={bloomberg}
                  className="opacity"
                  alt="bloomberg logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646685695/upwork_bd69bfd461.webp"
                  className="opacity"
                  alt="upwork logo"
                  loading="lazy"
                />
              </div>

              <div>
                <img
                  src={youtube}
                  alt="youtube logo"
                  className="opacity"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={microsoft}
                  className="opacity"
                  alt="microsoft logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646686733/hackernoon_a6d1b6ded0.svg"
                  className="opacity"
                  alt="hackernoon logo"
                  loading="lazy"
                />
              </div>
            </div>
            <div className=" company-logo-articles-container-logo  company-logo-articles-container-logo2-companies">
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646685678/stripe_02e6426027.webp"
                  className="opacity"
                  alt="stripe logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646685194/ycombinator_8aa1a2e726.webp"
                  className="opacity"
                  alt="ycombinator logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646686022/shopify_b90a51f044.webp"
                  className="opacity"
                  alt="shopify logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646685641/angellist_28044a2eab.webp"
                  className="opacity"
                  alt="angelList logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646685660/indeed_279afa4a87.webp"
                  className="opacity"
                  alt="indeed logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646689838/toptal_91ab1ce53d.webp"
                  className="opacity"
                  alt="toptal logo"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={zillow}
                  className="opacity"
                  alt="zillow logo"
                  loading="lazy"
                />
              </div>
              {/* <div>
                <img
                  src="https://res.cloudinary.com/ground-service-international/image/upload/v1646684226/github_13be3ed5f3.webp"
                  className="opacity"
                  alt="github"
                  loading="lazy"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyLogo;
