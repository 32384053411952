import axios from "axios";

const REVIEWS_BY_MAJORS_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/review/combine`;
const buildGetAllReviewsByMajorsConfig = () => ({
  method: "get",
  url: `${REVIEWS_BY_MAJORS_BASE_URL}`,
  headers: {
    Accept: "application/json",
  },
});

const REVIEWS_BY_MAJOR_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/review`;

const buildGetAllReviewsByMajorConfig = (major) => ({
  method: "get",
  url: `${REVIEWS_BY_MAJOR_BASE_URL}/${major}`,
  params: {},
  headers: {
    Accept: "application/json",
  },
});

// ADD POST REQUEST TO STORE REVIEW
const buildPostReviewByMajorConfig = (review) => ({
  method: "POST",
  url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/review/create`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    mode: "no-cors", // Adding no-cors mode
  },
  data: JSON.stringify({
    firstName: review.firstName,
    lastName: review.lastName,
    anonymous: review.anonymous,
    major: review.major,
    // school: review.school,
    graduationYear: review.graduationYear,
    country: review.country,
    jobTitle: review.jobTitle,
    employer: review.employer,
    careerSatisfaction: review.careerSatisfaction,
    adviceForStudents: review.adviceForStudents,
    overall: review.overall,
    recommend: review.recommend,
    challengeFaced: review.challengeFaced,
    majorRecommendation: review.majorRecommendation,
    opportunitiesAndAdvantages: review.opportunitiesAndAdvantages,
    levelOfDifficulty: review.levelOfDifficulty,
    additionalComment: review.additionalComment,
    salary: review.salary,
    email: review.email,
    yearsOfExperience: review.experience,
    isProfessional: review.isProfessional,
    isGraduated: review.isGraduated,
  }),
});

export const getAllReviewsByMajors = (setState, setLoading) => {
  setLoading(true);
  axios(buildGetAllReviewsByMajorsConfig())
    .then((response) => {
      if (response && response.data) {
        setState(response.data);
      }
    })
    .catch((e) => {
      console.log("error", e.data);
      console.log("response data", e.response.data);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getAllReviewsByMajor = async (setState, setLoading, major) => {
  await setLoading(true);
  await axios(buildGetAllReviewsByMajorConfig(major))
    .then((response) => {
      console.log(response);
      if (response && response.data) {
        console.log(response.data);
        setState(response.data);
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const postReviewByMajor = async (setState, review) => {
  // await setLoading(false);
  try {
    const response = await axios(buildPostReviewByMajorConfig(review));

    if (response && response.status === 201) {
      // await console.log(response.data);
      setState(response.data);
    }

    return response; // Return the response object
  } catch (error) {
    console.error("Error submitting review:", error);
    // setLoading(true);
    throw error; // Re-throw the error to be caught by the calling function
  }
  // finally {
  //   setLoading(true);
  // }
};
