import React from "react";

const DisplayCorrectFormat = ({ text }) => {
  // Convert newline characters to <br> elements
  const formattedText = text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return <div>{formattedText}</div>;
};

export default DisplayCorrectFormat;
