import React, { useEffect, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./successMessage.scss";

function SuccessMessage({
  user,
  handleCloseButtonSuccess,
  closeButtonModelSuccess,
}) {
  const closeButton = useRef();
  useEffect(() => {
    if (!closeButtonModelSuccess) {
      console.log("gabriel", closeButtonModelSuccess);
      closeButton.current.addEventListener("click", handleCloseButtonSuccess);
    }
    return () => {
      closeButton.current.removeEventListener(
        "click",
        handleCloseButtonSuccess
      );
    };
  }, [closeButtonModelSuccess]);
  return (
    <div className="outer-container-model-success-upload-review-sucess">
      <div className="inner-container-model-success-upload-review">
        <FaCheckCircle className="model-success-check-circle" />
        <div>
          <h3>Success</h3>
          {user && <span>{`Hey ${user.given_name},`}</span>}
          <p>
            Thank you for sharing your valuable experience! Your review has been
            successfully submitted and will help others make informed decisions
            about their academic and career paths.
          </p>
        </div>
      </div>
      <span ref={closeButton} className=" btn-danger-close ">
        &times;
      </span>
    </div>
  );
}

export default SuccessMessage;
