import React from "react";
import uuid from "react-uuid";
import img1 from "../../assets/SociauMedia/facebook.jpg";
import img2 from "../../assets/SociauMedia/github.jpg";
import img3 from "../../assets/SociauMedia/TikTok-Logo.jpg";
import img4 from "../../assets/SociauMedia/instagram.jpg";
import img5 from "../../assets/SociauMedia/twitter.jpg";
import img6 from "../../assets/SociauMedia/linkedin.jpg";
import img7 from "../../assets/SociauMedia/youtube.jfif";
import "./sociauxLinks.scss";

const data = [
  {
    id: uuid(),
    url: "https://www.facebook.com/gabrielandy.fono/",
    name: "facebook",
    img: img1,
  },
  {
    id: uuid(),
    url: "https://github.com/fono97",
    name: "github",
    img: img2,
  },
  {
    id: uuid(),
    url: "https://www.tiktok.com/@gabrielandyfono",
    name: "tiktok",
    img: img3,
  },
  {
    id: uuid(),
    url: "https://www.instagram.com/gabriel_fono/",
    name: "instagram",
    img: img4,
  },
  {
    id: uuid(),
    url: "https://x.com/fono_gabriel",
    name: "twitter",
    img: img5,
  },
  {
    id: uuid(),
    url: "https://www.linkedin.com/in/gabriel-fono/",
    name: "linkdin",
    img: img6,
  },
  {
    id: uuid(),
    // url: "https://www.youtube.com/channel/UCosXzuWsan-tSsbV2hVdgYQ/",
    url: "https://www.youtube.com/@gabrielfono844",
    name: "youtube",
    img: img7,
  },
];
const sociauxLogo = () => {
  return data.map(({ id, name, img, url }) => {
    return (
      <a href={url} key={id} target="_blank">
        <div className="container-image-link">
          <img src={img} alt={name} />
        </div>
      </a>
    );
  });
};

const SociauxLinks = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#059862",
      }}
    >
      <div className="container-sociaux-links">{sociauxLogo()}</div>
    </div>
  );
};

export default SociauxLinks;
