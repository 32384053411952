import React, { useContext, useEffect, useState, useMemo } from "react";
import Title from "../Title/Title";
import { Link } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import Review from "../Review/Review";
import uuid from "react-uuid";
import { getAllReviewsByMajors } from "../../utils/reviewByMajorService";
import Loading from "../../components/Loading";
// import VideoEmbed from "../../components/VideoEmbed";
import Video from "../../components/video";
import {
  convertHyphenToSpace,
  convertSpaceToHyphen,
} from "../../utils/RegExpUtil";

import "./reviews.scss";
import computerScience from "../../assets/img/success.jpeg";
import { images } from "../../storage/StorageService";
import emoji from "../../assets/companyLogo/no_result_emoji.png";
import SearchMajors from "../SearchMajors/SearchMajors";

const Reviews = (props) => {
  const [error, setError] = useState();
  const [reviewByMajor, setReviewByMajor] = useState([]);
  const [loadingHome, setLoadingHome] = useState(false);
  const [myArray, setMyArray] = useState([]);
  const [reviews, setReviews] = useState([]);
  //   const { featuredBlog, loading } = useContext(blogContext);
  //   const featured = featuredBlog();
  //   const { title, showLink } = props;
  //   if (loading) {
  //     return <Loading title=" all blogs are currently loading ..." />;
  //   }

  useEffect(() => {
    getAllReviewsByMajors(setReviewByMajor, setLoadingHome);
  }, []);

  useEffect(() => {
    console.log("all data back");
  }, [reviewByMajor]);
  const featured = [
    {
      major: "Chemistry🧪",
      id: uuid(),
      backgroundMajor: "Chimistry",
      type: "popular",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Software engineering🖥️",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "average",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "English Literature📚",
      id: uuid(),
      backgroundMajor: computerScience,
      type: null,
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "International Relations🌐",
      id: uuid(),
      backgroundMajor: computerScience,
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Business Administration💼 ",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Economics📈",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "popular",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Political Science🗳️ ",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Theater Arts🎭",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Fine Arts🎨",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Aerospace Engineering🚀",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "History🏛️",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Psychology🧠",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Journalism📰",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Environmental Science🌍",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
    {
      major: "Statistics📊",
      id: uuid(),
      backgroundMajor: computerScience,
      type: "",
      overall: "31",
      jobs: "50",
      cost: "100",
      fun: "60",
      quality: "10",
    },
  ];
  const noResultsEmojis = ["😐", "😶", "🤔", "🧐"];
  const randomNoResultEmoji = useMemo(
    () => noResultsEmojis[Math.floor(Math.random() * noResultsEmojis.length)],
    [reviewByMajor]
  );

  // useEffect(() => {
  //   console.log(reviewByMajor);
  // }, [reviewByMajor]);

  // useEffect(() => {
  //   const updatedArray = reviewByMajor.map((item) => {
  //     const matchingImage = images.find(
  //       (image) =>
  //         convertSpaceToHyphen(image.name.toLowerCase()) ===
  //         item.major.toLowerCase()
  //     );

  //     if (matchingImage) {
  //       return {
  //         ...item,
  //         photo: matchingImage.path,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         photo: "defaultImagePath.jpg",
  //       };
  //     }
  //   });
  //   setMyArray(updatedArray);
  // }, [reviewByMajor]);
  useEffect(() => {
    // Extract reviews arrays from each item in reviewByMajor and filter out empty arrays
    const nonEmptyReviews = reviewByMajor.map((item) => item.reviews);
    //.filter((reviews) => reviews.length > 0);

    // Set the non-empty reviews arrays in the state
    setReviews(...nonEmptyReviews);
  }, [reviewByMajor, reviews]);

  useEffect(() => {}, [reviews]);
  return (
    <section className="section">
      <Title title="Reviews about majors" specificTitle />
      <p
        style={{
          textAlign: "left",
          width: "1100px",
          maxWidth: "90%",
          margin: "0 auto",
          fontSize: "1.3rem",
        }}
      >
        You can submit your review by clicking "Add Review" to share your
        experience studying a specific major, certification, or self-learning
        program, as well as your work experience.<br></br> If the major or
        program isn't already listed, you can add it, and it will automatically
        appear on the platform. <br></br>
        By clicking on each major, you can also read reviews from others about
        their study experiences and how their chosen major helped them in their
        career.
      </p>

      <span className="wrapper-container-search-profile">
        <Link
          className="btn-primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "5px",
          }}
          to="/reviews/add-new-review"
        >
          <IoIosAdd style={{ display: "block", fontSize: "25px" }} />
          <span>Add Review</span>
          <span>🌟</span>
        </Link>
        {/* <SearchMajors featured={featured} /> */}
        <Video
          url="TeCdkV6VCSw"
          showPopUp="showPopUp"
          imageWindow="img-window"
        />
      </span>

      <div className="section-center">
        {loadingHome && (
          <Loading subtitle="Please wait ..." title="Fetching reviews..." />
        )}

        {reviews != null && reviews.length === 0 && !loadingHome && (
          <div style={{ textAlign: "center !important" }}>
            <span style={{ fontSize: "4rem" }}>{randomNoResultEmoji}</span>
            <p>No Results Found</p>
            <p>There is no reviews and majors on the platform</p>
            <span>Be the first to add yours</span>
          </div>
        )}

        {reviewByMajor.map((singleReview, index) => {
          return <Review key={index} {...singleReview} />;
        })}
      </div>
    </section>
  );
};

export default Reviews;
//https://res.cloudinary.com/ground-service-international/image/upload/v1716672442/ratemymajor_teacher.jpg
//teacher;

//https://res.cloudinary.com/ground-service-international/image/upload/v1716672665/ratemymajor_civilengineering.jpg
//civil_engineering
//https://res.cloudinary.com/ground-service-international/image/upload/v1716672749/ratemymajor_nursing.jpg
//nursing
