import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const StarRating = ({ rating, numberOfStars, isFiveStars }) => {
  const colors = {
    orange: "#FFBA5A",
    gray: "gray",
  };

  // Split the rating into integer and decimal parts

  rating = isFiveStars && numberOfStars == 5 ? rating / 2 : rating;
  const integerPart = Math.floor(rating);
  const decimalPart = rating - integerPart;

  // Determine how many full stars, half stars, and empty stars to display
  let fullStars = integerPart;
  let hasHalfStar = decimalPart >= 0.25 && decimalPart < 0.75;
  let emptyStars = numberOfStars - fullStars - (hasHalfStar ? 1 : 0);

  // Handle cases where the rating is close to the next integer
  if (decimalPart >= 0.75) {
    fullStars += 1;
  } else if (decimalPart >= 0.25) {
    hasHalfStar = true;
  }

  // Create an array of star elements to render
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <FaStar
        key={i}
        style={{ color: colors.orange, fontSize: "25px", marginLeft: 5 }}
      />
    );
  }
  if (hasHalfStar) {
    stars.push(
      <FaStarHalfAlt
        key="half"
        style={{ color: colors.orange, fontSize: "25px", marginLeft: 5 }}
      />
    );
  }
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <FaStar
        key={`empty-${i}`}
        style={{ color: colors.gray, fontSize: "25px", marginLeft: 5 }}
      />
    );
  }

  return <div className="star-rating">{stars}</div>;
};
export default StarRating;
