// import React from "react";

// const SvgUtil = () => {
//   return (
//     <div style={{ width: "50px", height: "50px" }}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 24 24"
//         //         width="100%"
//         //         height="100%"
//         style={{ width: "100%", height: "100%" }}
//       >
//         {/* <circle cx="12" cy="12" r="12" fill="#DEDEDE" /> */}
//         <path
//           //           fill="#000"
//           fillRule="evenodd"
//           d="M12 7a3 3 0 1 1-3 3 3 3 0 0 1 3-3zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 16c-4.125 0-7.5-1.92-7.5-4.5s3.375-4.5 7.5-4.5 7.5 1.92 7.5 4.5-3.375 4.5-7.5 4.5z"
//           clipRule="evenodd"
//         />
//       </svg>
//     </div>
//   );
// };

// export default SvgUtil;
import React from "react";

const SvgUtil = () => {
  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        overflow: "hidden",
        //         background: "white",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={{ width: "100%", height: "100%" }}
      >
        <circle cx="50" cy="50" r="50" fill="#DEDEDE" />
        <path
          //           fill="#DEDEDE"
          d="M12 0C5.373 0 0 5.373 0 12s12 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 4.5c2.484 0 4.5 2.016 4.5 4.5S14.484 13.5 12 13.5 7.5 11.484 7.5 9 9.516 4.5 12 4.5zM5.25 19.5c1.968-3.516 8.25-3.516 10.218 0H5.25z"
        />
      </svg>
    </div>
  );
};

export default SvgUtil;
