import styled from "styled-components";
export const StyleProduct = styled.div`
  width: ${({ background }) => `${background}%`};
  height: 100%;
  // background:#ff4742;
  // background:#ffc924;
  // background:rgb(54, 230, 127);
  background: ${({ background }) =>
    (parseInt(background) < 30 && "#ff4742") ||
    (parseInt(background) >= 31 && parseInt(background) < 50 && "#ffc924") ||
    (parseInt(background) >= 50 &&
      parseInt(background) <= 100 &&
      "rgb(54, 230, 127)")};
  border-radius: 5px;
  /* transition: var(--mainTransition); */
`;

export const DifficultyLevel = styled.div`
  width: ${({ background }) => `${background}%`};
  height: 100%;
  // background:#ff4742;
  // background:#ffc924;
  // background:rgb(54, 230, 127);
  background: ${({ background }) =>
    (parseInt(background) <= 30 && "rgb(54, 230, 127)") ||
    (parseInt(background) >= 31 && parseInt(background) <= 60 && "#ffc924") ||
    (parseInt(background) > 61 && parseInt(background) <= 100 && "#ff4742")};
  border-radius: 5px;
  /* transition: var(--mainTransition); */
`;
// export default StyleProduct;
