import React, { useState, useEffect, useContext } from "react";
import QuickLinks from "../QuickLinks/QuickLinks";
import ImportantLinks from "../ImportantLinks/ImportantLinks";
import ContactUs from "../ContactUs/ContactUs";
import Subscribe from "../Subscribe/Subscribe";
import axios from "axios";
import "./Footer.scss";
import { blogContext } from "../../../App";

const Footer = () => {
  const initialValues = {
    email: "",
  };
  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }

    return errors;
  };

  const { emails } = useContext(blogContext);

  const emailExist = (userEmail) => {
    let emailExistant = {};

    for (let i = 0; i < emails.length; i++) {
      const singleEmail = emails[i];
      if (userEmail.email === singleEmail.email) {
        emailExistant.message = " 😲 Sorry, This email already existed 😲";
        emailExistant.email = userEmail.email;
      }
    }
    return emailExistant;
  };

  const handleSubscription = (e) => {
    e.preventDefault();
    setFormErrors(validate(subscription));
    setUserEmail(emailExist(subscription));
    setIsSubmit(true);
    if (subscription.email === "") {
      return;
    }
    if (emails) {
      for (let i = 0; i < emails.length; i++) {
        const singleEmail = emails[i];
        if (subscription.email === singleEmail.email) {
          return;
        }
      }
    }
    axios.post("http://localhost:1337/Emails", {
      ...subscription,
    });

    setSubscription({
      email: "",
    });
  };

  return (
    <footer>
      <section className="section footer">
        <div className="container-new-letter">
          <p>Stay informed ...</p>
          <p>
            Join community of entrepreneurs and learn how to grow your business
          </p>
          {Object.keys(userEmail).length !== 0 ? (
            <div className="successReview">{userEmail.message}</div>
          ) : (
            Object.keys(formErrors).length === 0 &&
            isSubmit && (
              <>
                <div className="successReview">
                  Thanks 😊 , We just added you on our mailing list 😊
                </div>
              </>
            )
          )}
          <form className="new-letters" onSubmit={handleSubscription}>
            <input
              name="email"
              placeholder="Enter your email address  ..."
              type="email"
              value={subscription.email}
              onChange={handleChange}
            />
            <button type="submit">Join Today</button>
          </form>
          <p className="errorReview"> {formErrors.email}</p>
        </div>

        <div className="container-footer">
          <QuickLinks />
          <ImportantLinks />
          <ContactUs />
          <Subscribe />
        </div>
        <h4 className="copyright">
          copyright&copy;{new Date().getFullYear()}
          <span>RateMyMajor</span> all rights reserved
        </h4>
      </section>
    </footer>
  );
};

export default Footer;
