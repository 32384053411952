import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import ReactGA from "react-ga4";
import hompageImage from "../assets/seo_og_image/home_page_image.jpg";
import Share from "../components/Share";
import Reviews from "../components/Reviews/";

const Home = () => {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home page",
    });
  }, []);
  const initialValues = {
    email: "",
  };

  const [subscription, setSubscription] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userEmail, setUserEmail] = useState({});
  const [error, setError] = useState(null);
  const [signed, setSigned] = useState("");

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setSubscription({ ...subscription, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // Additional logic when there are no form errors and the form is submitted
    }
  }, [formErrors]);

  const validate = (users) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!users.email) {
      errors.email = "Email is required!!!";
    } else if (!regex.test(users.email)) {
      errors.email = "This is not the valid email format!";
    }

    return errors;
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setError(null);
    setFormErrors(validate(subscription));
    setIsSubmit(true);

    let email = subscription.email;
    /* const api_url = `/api/memberAdd/${email}`; */
    const api_url = `https://backend.ratemymajor.io/api/memberAdd/${email}`;

    try {
      const response = await fetch(api_url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.title === "Member Exists") {
        setSigned("You are already signed up for this email  😥 service.");
      } else if (data.title !== "") {
        setSigned("Thanks 😊, We just added you on our mailing list");
        setSubscription({
          email: "", // Clearing the email field
          subscribe: "",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      if (
        error instanceof Response &&
        (error.status === 400 || error.status === 500)
      ) {
        setError("An error occurred with the server. Please try again later.");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          🎓 RateMyMajor.io | Explore & Choose the Perfect Major | 🚀
        </title>
        <link rel="canonical" href="/" />

        <meta
          name="description"
          content="Discover real-life insights on RateMyMajor.io. Explore reviews, blogs, and podcasts for informed decisions on majors and careers. 🎓💡🌐"
        />

        <meta
          name="keywords"
          content="college majors, choose a perfect major,  career planning, education exploration, student reviews, major decision, academic success, career guidance, higher education, degree programs, career pathways"
        />

        {/* <meta name="author" content="Gabriel Andy Fono" /> */}

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="RateMyMajor.io | Explore, Choose the Perfect Major and Build a Successful Career | 🚀"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Explore real-life insights, reviews, and career guidance on RateMyMajor.io. Your gateway to informed decisions and a successful academic journey. 🎓💡🌐"
        />

        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta property="og:image" content={hompageImage} />
        {/* Replace 'url_to_your_page' with the actual page URL */}
        <meta property="og:url" content="https://ratemymajor.io" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content={hompageImage} />
        <meta name="twitter:site" content="https://twitter.com/FonoGabriel" />

        <meta
          property="twitter:title"
          content="RateMyMajor.io | Explore, Choose the Perfect Major and Build a Successful Career | 🚀"
        />

        <meta
          name="twitter:description"
          content="Navigate your academic journey with confidence on RateMyMajor.io, an invaluable collection of real-life insights from graduates. 🎓💡 Explore firsthand reviews on various majors and careers, empowering you to make informed decisions. Join a supportive community, dive into insightful blogs, and tune in to podcasts featuring interviews about majors and careers. 🌐📚🎙️ Your gateway to choosing the perfect major and shaping a successful future."
        />
        {/* Replace 'url_to_your_thumbnail_image' with the actual image URL */}
        <meta name="twitter:image" content={hompageImage} />
      </Helmet>

      <Layout>
        <Hero hero="defaultHero" videobackground>
          <Banner
            title="The Ultimate Major Exploration Platform To Help Students or anyone choose right Major And Find Their Path For better Future 🚀."
            subtitle="At RateMyMajor, we help students or anyone  choose major based on life experience shared by others in school and in the workforce🌐📚🎙️"
          >
            <form onSubmit={handleSubscription}>
              <span style={{ display: "block", fontWeight: 900 }}>
                Join our list to get update about the plateform 📧.
              </span>

              {Object.keys(userEmail).length !== 0 ? (
                <div className="successReview">{userEmail.message}</div>
              ) : (
                Object.keys(formErrors).length === 0 &&
                isSubmit &&
                (signed.length > 0 &&
                signed ===
                  "You are already signed up for this email  😥 service." ? (
                  <h6
                    style={{
                      fontWeight: 900,
                      color: "red",
                    }}
                  >
                    Your email already existed 😥!!!
                  </h6>
                ) : (
                  <h6
                    style={{
                      fontWeight: 900,
                      color: "var(--primaryColor)",
                    }}
                  >
                    Thanks 😊 , We just added you on our mailing list
                  </h6>
                ))
              )}
              <input
                style={{
                  height: "40px",
                  outline: "none",
                  fontSize: "16px",
                  width: "250px",
                  maxWidth: "50%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  textIndent: "10px",
                }}
                name="email"
                placeholder="Enter your email 📧 ..."
                type="email"
                value={subscription.email}
                onChange={handleChange}
              />
              <button className="btn-primary" type="submit">
                Join Today
              </button>
              <h6 style={{ fontWeight: "800", color: "red" }}>
                {formErrors.email}
              </h6>
              <h6 style={{ fontWeight: "800", color: "red" }}>{error}</h6>
            </form>
          </Banner>
        </Hero>
        <Reviews title="reviews about major and careers from graduates" />
      </Layout>
    </>
  );
};

export default Home;
