import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaStar } from "react-icons/fa";
import uuid from "react-uuid";

const Scroll = styled.div`
  ::-webkit-scrollbar {
    width: 2px;
    color: var(--primaryColor);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 2px;
  }
  ${({ primary }) =>
    primary &&
    css`
      overflow-y: scroll;
      height: 160px;
      font-size: 15px;
      text-align: left;
      color: #445d6e;
      line-height: 1.7;
      white-space: pre-line;
      overflow-x: none !important;
    `}
`;

const colors = {
  orange: "#FFBA5A",
  gray: "gray",
  fontSize: "25rem",
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    margin: ".5rem auto",
  },
};

const Rating = (props) => {
  const {
    rating,
    ratingUsers,
    setCurrentValue,
    setHoverValue,
    currentValue,
    hoverValue,
    name,
  } = props;

  const userRating = () => {
    const stars = [];

    const orangePercentage = parseInt(ratingUsers) / 100;
    const fullStarCount = Math.floor(orangePercentage * rating);
    const partialStarPercentage = orangePercentage * rating - fullStarCount;

    for (let i = 1; i <= rating; i++) {
      stars.push(i);
    }

    const handleClick = (value) => {
      setCurrentValue(value);
    };

    const handleMouseOver = (newHoverValue) => {
      setHoverValue(newHoverValue);
    };

    const handleMouseLeave = () => {
      setHoverValue(undefined);
    };

    return (
      <div style={styles.container}>
        {stars.map((star, index) => {
          let starColor = colors.gray;

          if (index < fullStarCount) {
            starColor = colors.gray; // Full orange stars
          } else if (index === fullStarCount && partialStarPercentage > 0) {
            starColor = `rgba(255, 186, 90, ${partialStarPercentage})`; // Partial orange for the last star
          }

          return (
            <span
              key={uuid()}
              style={{
                marginLeft: 5,
                cursor: "pointer",
                fontSize: "18px", // Adjust the size as needed
              }}
            >
              <FaStar
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
                color={
                  (hoverValue || currentValue) > index
                    ? colors.orange
                    : colors.gray
                }
              />
            </span>
          );

          //  (
          //   <FaStar
          //     key={uuid()}
          //     style={{
          //       marginLeft: 5,
          //       cursor: "pointer",
          //       fontSize: "24px !important", // Adjust the size as needed
          //     }}
          //     onClick={() => handleClick(index + 1)}
          //     onMouseOver={() => handleMouseOver(index + 1)}
          //     onMouseLeave={handleMouseLeave}
          //     color={
          //       (hoverValue || currentValue) > index
          //         ? colors.orange
          //         : colors.gray
          //     }
          //   />
          // );
        })}
      </div>
    );
  };

  return <>{userRating()}</>;
};

export default Rating;
