export const majors = [
  "Computer Science 👩‍💻",
  "Psychology 🧠",
  "Engineering 🛠️",
  "Biology 🌿",
  "Business 🏢",
  "Art 🎨",
  "Civil Engineering 🏗️",
  "Mechanical Engineering ⚙️",
  "Nurse 👩‍⚕️",
  "Teacher 👩‍🏫",
  "Accountant 💰",
  "Electrical Engineer ⚡",
  "Data Analysis 📊",
  "Data Entry 💻",
  "Journalism 📰",
  "Marketing 📈",
  "Ethical Hacker 💻🔒",
  "Cyber Security Engineer 🔐🛡️",
];

export const countries = [
  { name: "United States", emoji: "🇺🇸" },
  { name: "United Kingdom", emoji: "🇬🇧" },
  { name: "Canada", emoji: "🇨🇦" },
  { name: "Germany", emoji: "🇩🇪" },
  { name: "France", emoji: "🇫🇷" },
  { name: "Italy", emoji: "🇮🇹" },
  { name: "Australia", emoji: "🇦🇺" },
  { name: "Spain", emoji: "🇪🇸" },
  { name: "Netherlands", emoji: "🇳🇱" },
  { name: "Brazil", emoji: "🇧🇷" },
  { name: "Mexico", emoji: "🇲🇽" },
  { name: "Japan", emoji: "🇯🇵" },
  { name: "South Korea", emoji: "🇰🇷" },
  { name: "China", emoji: "🇨🇳" },
  { name: "India", emoji: "🇮🇳" },
  { name: "Russia", emoji: "🇷🇺" },
  { name: "Sweden", emoji: "🇸🇪" },
  { name: "Norway", emoji: "🇳🇴" },
  { name: "Denmark", emoji: "🇩🇰" },
  { name: "Finland", emoji: "🇫🇮" },
  { name: "Switzerland", emoji: "🇨🇭" },
  { name: "Austria", emoji: "🇦🇹" },
  { name: "Greece", emoji: "🇬🇷" },
  { name: "Turkey", emoji: "🇹🇷" },
  { name: "Argentina", emoji: "🇦🇷" },
];
export const salaries = [
  "25k - 34k",
  "35k - 49k",
  "50k - 75k",
  "75k - 100k",
  "101k - 149k",
  "150k - 199k",
  "200k+",
];
export const positions = [
  "Software Engineer",
  "Registered Nurse",
  "Data Scientist",
  "Marketing Manager",
  "Financial Analyst",
  "Civil Engineer",
  "Graphic Designer",
  "Human Resources Manager",
  "Electrician",
  "Dental Hygienist",
  "Physical Therapist",
  "Mechanical Engineer",
  "Teacher",
  "Pharmacist",
  "Operations Manager",
  "Web Developer",
  "Accountant",
  "Project Manager",
  "Social Worker",
  "Sales Representative",
  "Nurse Practitioner",
  "Medical Technologist",
  "Architect",
  "Lawyer",
  "Chef",
];
export const employers = [
  "Google",
  "Amazon",
  "Apple",
  "Microsoft",
  "Facebook",
  "Tesla",
  "Netflix",
  "IBM",
  "Adobe",
  "Oracle",
  "Salesforce",
  "Cisco",
  "Intel",
  "HP",
  "Dell",
  "Sony",
  "Samsung",
  "LG",
  "Toyota",
  "Honda",
  "General Motors",
  "Ford",
  "Volkswagen",
  "BMW",
  "Mercedes-Benz",
  "Boeing",
  "Airbus",
  "Lockheed Martin",
  "Raytheon Technologies",
  "SpaceX",
  "NASA",
  "McKinsey & Company",
  "Bain & Company",
  "Boston Consulting Group",
  "Accenture",
  "Deloitte",
  "PricewaterhouseCoopers (PwC)",
  "Ernst & Young (EY)",
  "KPMG",
  "Goldman Sachs",
  "JPMorgan Chase",
  "Bank of America",
  "Citigroup",
  "Morgan Stanley",
  "UBS",
  "Credit Suisse",
  "HSBC",
  "BlackRock",
  "Fidelity Investments",
  "Vanguard",
  "Berkshire Hathaway",
];

export const yearsOfExperienceInTheField = [
  "0 - 1",
  "2 - 3",
  "3 - 6",
  "6 - 8",
  "8 - 10",
  "10 - 15",
  "15 - 18",
  "18 - 20",
  "over 20",
];

export const schools = [
  "Harvard",
  "Stanford",
  "Massachusetts Institute of Technology (MIT)",
  "University of Cambridge",
  "University of Oxford",
  "California Institute of Technology (Caltech)",
  "Princeton University",
  "University of Chicago",
  "Yale University",
  "Columbia University",
  "University of California, Berkeley",
  "Imperial College London",
  "ETH Zurich",
  "University College London (UCL)",
  "University of Toronto",
  "National University of Singapore (NUS)",
  "Tsinghua University",
  "Peking University",
  "University of Tokyo",
  "University of Melbourne",
  "University of Sydney",
  "University of Cape Town",
  "University of Pretoria",
  "Stellenbosch University",
  "University of Nairobi",
  "Cairo University",
  "University of Lagos",
  "Kwame Nkrumah University of Science and Technology",
  "University of Ghana",
  "American University of Beirut",
  "King Saud University",
  "Seoul National University",
  "Kyoto University",
  "University of Hong Kong",
  "Indian Institute of Technology (IIT) Bombay",
  "University of Delhi",
  "Fudan University",
  "Shanghai Jiao Tong University",
  "University of Manchester",
  "Ludwig Maximilian University of Munich",
  "Technical University of Munich",
  "University of Copenhagen",
  "Sorbonne University",
  "Ecole Polytechnique",
  "Trinity College Dublin",
  "University of Edinburgh",
  "University of Amsterdam",
  "University of Zurich",
  "Korea Advanced Institute of Science and Technology (KAIST)",
  "University of Queensland",
  "University of Lagos",
  "Obafemi Awolowo University",
  "University of Ibadan",
  "Ahmadu Bello University",
  "Covenant University",
  "University of Nigeria, Nsukka",
  "Babcock University",
  "Bayero University Kano",
  "Federal University of Technology, Minna",
  "Lagos State University",
  "University of Yaoundé I",
  "University of Buea",
  "University of Douala",
  "University of Dschang",
  "University of Ngaoundéré",
  "Université Catholique d'Afrique Centrale (UCAC)",
  "Université de Maroua",
  "Institut Supérieur de Technologie et Design Industriel (ISTDI)",
  "Université des Montagnes",
  "Université Protestante d'Afrique Centrale",
  "University of Cape Town",
  "University of Pretoria",
  "Stellenbosch University",
  "University of the Witwatersrand",
  "University of Johannesburg",
  "Rhodes University",
  "University of KwaZulu-Natal",
  "North-West University",
  "Nelson Mandela University",
  "University of the Western Cape",
  "University of Ghana",
  "Kwame Nkrumah University of Science and Technology (KNUST)",
  "Ashesi University",
  "University of Cape Coast",
  "University for Development Studies",
  "Valley View University",
  "Central University",
  "Presbyterian University College",
  "Regent University College of Science and Technology",
  "Ghana Institute of Management and Public Administration (GIMPA)",
];
