import React from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import CompanyLogo from "../../components/CompanyLogo/CompanyLogo";
import Title from "../../components/Title";
import MajorReviewForm from "../../components/MajorReviewForm/MajorReviewForm";

function AddReview() {
  return (
    <Layout>
      {/* <Hero hero="defaultHero" videobackground>
        <Banner
          title="The Ultimate Major Exploration Platform helping students decide on their majors and explore opportunities aligned with their chosen fields."
          subtitle="RateMyMajor offers genuine feedback from college graduates, providing comprehensive insights into their majors and sharing details about their current careers. 🎓💡 Additionally, RateMyMajor has forums for students to connect, blogs for guidance, and podcasts where we interview people about their majors and careers. 🌐📚🎙️"
        ></Banner>
      </Hero> */}
      <section className="section-jobs">
        <Title
          title="Ready to add your review and help million of people"
          specificTitle
        />
        <div className="section-jobs-description">
          <p>
            Take just five minutes to fill out the form below. Your input can
            make a real difference, helping students, job seekers, and those
            considering going back to school. Your insights may not only impact
            someone's career and life but also broaden their understanding of
            major and career opportunities.<br></br>
            most professionals reviewiers work on this companies below
          </p>
        </div>
        <CompanyLogo />
        <MajorReviewForm />
      </section>
    </Layout>
  );
}

export default AddReview;
