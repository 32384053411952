import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import "./majorReviewForm.scss";
import uuid from "react-uuid";
import {
  employers,
  positions,
  salaries,
  countries,
  majors,
  yearsOfExperienceInTheField,
  schools,
} from "../../utils/experienceFormData";
import { postReviewByMajor } from "../../utils/reviewByMajorService";
import Rating from "../Rating";
import { RiArrowDropDownLine } from "react-icons/ri";
import DarkMode from "../DarkMode";
import SuccessMessage from "../model/successMessage";
import ErrorMessage from "../model/errorMessage";

const MajorReviewForm = () => {
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [createReviewByMajor, setCreateReviewByMajor] = useState([]);
  const [errorReviewByMajor, setErrorReviewByMajor] = useState([]);
  const [loadingPostReview, setLoadingPostReview] = useState(false);
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [closeButtonModelSuccess, setCloseButtonModelSuccess] = useState(false);
  const [closeButtonModelError, setCloseButtonModelError] = useState(false);
  const [displayModelSubmit, setDisplayModelSubmit] = useState(false);
  const [showModelSubmit, setShowModelSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isGraduated, setIsGraduated] = useState(
    localStorage.getItem("isGraduated")
  );
  const [isProfessional, setIsProfessional] = useState(
    localStorage.getItem("isProfessional")
  );

  const closeButton = useRef(null);
  const handleModelSubmit = (e) => {
    e.preventDefault();
    setDisplayModelSubmit((prev) => !prev);
  };

  const handleCloseButtonSuccess = () => {
    setCloseButtonModelSuccess((prev) => !prev);
  };
  const handleCloseButtonError = () => {
    setCloseButtonModelError((prev) => !prev);

    //setDisplayModelSubmit((prev) => !prev);
  };
  const handleShowModelSubmit = () => {
    //setDisplayModelSubmit((prev) => !prev);
    setShowModelSubmit((prev) => !prev);
  };

  const body = document.body;

  const initialRatingState = {
    value: 0,
    hover: undefined,
  };

  const [majorRecommendationRating, setMajorRecommendationRating] = useState(
    () => {
      const savedRating = localStorage.getItem("majorRecommendation");
      return savedRating ? JSON.parse(savedRating) : initialRatingState;
    }
  );

  const [careerSatisfactionRating, setCareerSatisfactionRating] = useState(
    () => {
      const savedRating = localStorage.getItem("careerSatisfactionRating");
      return savedRating ? JSON.parse(savedRating) : initialRatingState;
    }
  );

  const [levelOfDifficultyRating, setLevelOfDifficultyRating] = useState(() => {
    const savedRating = localStorage.getItem("levelOfDifficultyRating");
    return savedRating ? JSON.parse(savedRating) : initialRatingState;
  });

  const [overallRating, setOverallRating] = useState(() => {
    const savedRating = localStorage.getItem("overallRating");
    return savedRating ? JSON.parse(savedRating) : initialRatingState;
  });

  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    const savedCareerSatisfactionRating = JSON.parse(
      localStorage.getItem("careerSatisfactionRating")
    );
    const savedOverallRating = JSON.parse(
      localStorage.getItem("overallRating")
    );
    const savedLevelOfDifficultyRating = JSON.parse(
      localStorage.getItem("levelOfDifficultyRating")
    );

    const savedMajorRecommendationRating = JSON.parse(
      localStorage.getItem("majorRecommendation")
    );
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          firstName: "",
          lastName: "",
          anonymous: true,
          major: "",
          school: "",
          graduationYear: "",
          country: "",
          jobTitle: "",
          employer: "",
          careerSatisfaction: savedCareerSatisfactionRating
            ? Math.max(
                savedCareerSatisfactionRating.value,
                savedCareerSatisfactionRating.hover
              )
            : "",
          adviceForStudents: "",
          overall: savedOverallRating ? savedOverallRating.value : "",
          challengeFaced: "",
          majorRecommendation: savedMajorRecommendationRating
            ? Math.max(
                savedMajorRecommendationRating.value,
                savedMajorRecommendationRating.hover
              )
            : "",
          opportunitiesAndAdvantages: "",
          levelOfDifficulty: savedLevelOfDifficultyRating
            ? Math.max(
                savedLevelOfDifficultyRating.value,
                savedLevelOfDifficultyRating.hover
              )
            : "",
          additionalComment: "",
          salary: "",
          email: "",
          experience: "",
          isProfessional: isProfessional,
          isGraduated: isGraduated,
        };
  });

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem(
      "careerSatisfactionRating",
      JSON.stringify(careerSatisfactionRating)
    );
  }, [careerSatisfactionRating]);

  useEffect(() => {
    localStorage.setItem("overallRating", JSON.stringify(overallRating));
  }, [overallRating]);

  useEffect(() => {
    localStorage.setItem(
      "levelOfDifficultyRating",
      JSON.stringify(levelOfDifficultyRating)
    );
  }, [levelOfDifficultyRating]);

  useEffect(() => {
    localStorage.setItem(
      "majorRecommendation",
      JSON.stringify(majorRecommendationRating)
    );
  }, [majorRecommendationRating]);

  useEffect(() => {
    if (displayModelSubmit) {
      closeButton.current.addEventListener("click", (e) =>
        handleModelSubmit(e)
      );
    }
    return () => {
      closeButton.current.removeEventListener("click", (e) =>
        handleModelSubmit(e)
      );
    };
  }, [displayModelSubmit]);

  const handleToggleList = (dropdownName, event) => {
    event.stopPropagation();
    setCurrentDropdown(dropdownName === currentDropdown ? null : dropdownName);
  };

  const dropdownContainerRefs = {
    major: useRef(null),
    school: useRef(null),
    graduationYear: useRef(null),
    country: useRef(null),
    jobTitle: useRef(null),
    salary: useRef(null),
    employer: useRef(null),
    experience: useRef(null),
  };

  const handleOnFocusList = (dropdownName, event) => {
    event.stopPropagation();
    setCurrentDropdown(dropdownName === currentDropdown ? null : dropdownName);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        currentDropdown != null &&
        dropdownContainerRefs[currentDropdown].current &&
        !dropdownContainerRefs[currentDropdown].current.contains(event.target)
      ) {
        setCurrentDropdown(null); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownContainerRefs, currentDropdown]);

  const currentYear = new Date().getFullYear();
  const yearsRange = 50; // Adjust the range of years as needed
  const years = Array.from(
    { length: yearsRange },
    (_, index) => currentYear - index
  );

  useEffect(() => {
    // Set default graduation year to the current year
    setFormData({ ...formData, graduationYear: currentYear.toString() });
  }, []); // Run this effect once when the component mounts

  // useEffect(() => {
  //   if (!closeButtonModelError) {
  //     body.style.overflow = "scroll";
  //   }
  // }, [closeButtonModelError]);

  useEffect(() => {
    if (isAuthenticated && user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: user.email || "",
        firstName: user.given_name || "",
        lastName: user.family_name || "",
        careerSatisfaction:
          Math.max(
            careerSatisfactionRating.value,
            careerSatisfactionRating.hover || 0
          ) || "",
        overall: Math.max(overallRating.value, overallRating.hover || 0) || "",
        majorRecommendation:
          Math.max(
            majorRecommendationRating.value,
            majorRecommendationRating.hover || 0
          ) || "",
        levelOfDifficulty:
          Math.max(
            levelOfDifficultyRating.value,
            levelOfDifficultyRating.hover || 0
          ) || "",
        isProfessional,
        isGraduated,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: "", // Set email to empty string
        firstName: "",
        lastName: "",
        careerSatisfaction: "",
        overall: "",
        levelOfDifficulty:
          Math.max(
            levelOfDifficultyRating.value,
            levelOfDifficultyRating.hover || 0
          ) || "",
        majorRecommendation:
          Math.max(
            majorRecommendationRating.value,
            majorRecommendationRating.hover || 0
          ) || "",
        isGraduated: "",
        isProfessional: "",
      }));
    }
  }, [
    isAuthenticated,
    user,
    careerSatisfactionRating.value,
    careerSatisfactionRating.hover,
    overallRating.value,
    overallRating.hover,
    majorRecommendationRating.value,
    majorRecommendationRating.hover,
    levelOfDifficultyRating.value,
    levelOfDifficultyRating.hover,
    isProfessional,
    isGraduated,
  ]);

  const handleChange = (e) => {
    let { name, type, checked } = e.target;
    const newValue =
      type === "checkbox" && name === "anonymous" ? checked : e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    setToggle((previous) => {
      return (previous = formData.anonymous);
    });
  };

  const switchToggle = () => {
    setToggle((previousToggle) => !previousToggle); // Toggle the value of toggle state
    setFormData((previousFormData) => ({
      ...previousFormData,
      anonymous: !previousFormData.anonymous, // Toggle the value of anonymous in formData
    }));
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("This is formError", formErrors);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};

    // Check for empty fields
    if (!values.major) {
      errors.major = "Major shouldn't be empty";
    }
    if (!values.graduationYear) {
      errors.graduationYear = "Year of Graduation shouldn't be empty";
    } else if (values.graduationYear > new Date().getFullYear()) {
      errors.graduationYear = "Year of Graduation cannot be in the future";
    }
    if (!values.country) {
      errors.country = "Country shouldn't be empty";
    }
    // if (!values.jobTitle) {
    //   errors.jobTitle = "Job Title shouldn't be empty";
    // }
    // if (!values.employer) {
    //   errors.employer = "Employer shouldn't be empty";
    // }
    // if (!values.overall) {
    //   errors.overall = "Overall rating shouldn't be empty";
    // }
    // if (!values.experience) {
    //   errors.experience = "Experience should have a value";
    // }

    if (!values.majorRecommendation) {
      errors.majorRecommendation = "Major recommendation shouldn't be empty";
    }
    // if (!values.careerSatisfaction) {
    //   errors.careerSatisfaction = "Career satisfaction shouldn't be empty";
    // }
    // Check word count for challengesFaced and adviceForStudents
    if (!values.challengeFaced) {
      errors.challengeFaced = "Challenges faced shouldn't be empty.";
    }
    if (values.challengeFaced && values.challengeFaced.trim().length < 20) {
      errors.challengeFaced = "Challenges faced should have at least 20 words";
    } else if (
      values.challengeFaced &&
      values.challengeFaced.trim().length > 2000
    ) {
      errors.challengeFaced = "Challenges faced should have at most 2000 words";
    }
    if (!values.adviceForStudents) {
      errors.adviceForStudents = "advice for students shouldn't be empty";
    }
    if (
      values.adviceForStudents &&
      values.adviceForStudents.trim().length < 20
    ) {
      errors.adviceForStudents =
        "Advice for students should have at least 20 words";
    } else if (
      values.adviceForStudents &&
      values.adviceForStudents.trim().length > 2000
    ) {
      errors.adviceForStudents =
        "Advice for students should have at most 2000 words";
    }

    if (!values.levelOfDifficulty) {
      errors.levelOfDifficulty = "Level of difficulty should have a value";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingPostReview(true);
    setCloseButtonModelError(false);
    setCloseButtonModelSuccess(false);
    setDisplayModelSubmit(false);
    setCreateReviewByMajor([]);

    try {
      const validationErrors = validate(formData); // Validate form data
      setFormErrors(validationErrors);

      // If there are validation errors, display error messages and abort submission
      if (Object.keys(validationErrors).length > 0) {
        console.error("Validation errors:", validationErrors);
        setIsSubmit(true);
        // Handle validation errors: Display error messages or perform other actions
        // For example, you can set state variables to display error messages to the user
        // setErrorMessages(validationErrors); // Assuming you have a state variable to store error messages
        return;
      }
      if (!isAuthenticated) {
        // User is not authenticated, initiate login process
        loginWithRedirect({
          // sessionStorage.setItem("redirectPath", window.location.pathname);
          //returnTo: window.location.pathname, // Redirect back to the form page after login
          returnTo: sessionStorage.setItem(
            "redirectPath",
            window.location.pathname
          ),
        });
        return;
      }
      // User is authenticated, proceed with form submission
      const response = await postReviewByMajor(
        setCreateReviewByMajor,
        formData
      );

      if (response != null) {
        // Remove form data from localStorage after successful submission
        setCreateReviewByMajor(response);

        localStorage.removeItem("formData");
        localStorage.removeItem("careerSatisfactionRating");
        localStorage.removeItem("overallRating");
        localStorage.removeItem("levelOfDifficultyRating");
        localStorage.removeItem("recommendRating");

        //Clear the form data by updating the state
        setFormData({
          anonymous: true,
          major: "",
          school: "",
          country: "",
          jobTitle: "",
          employer: "",
          careerSatisfaction: "",
          adviceForStudents: "",
          overall: "",
          challengeFaced: "",
          majorRecommendation: "",
          opportunitiesAndAdvantages: "",
          levelOfDifficulty: "",
          additionalComment: "",
          salary: "",
        });
        setMajorRecommendationRating(initialRatingState);
        setCareerSatisfactionRating(initialRatingState);
        setOverallRating(initialRatingState);
        setLevelOfDifficultyRating(initialRatingState);
      }
      setLoadingPostReview(false);
    } catch (error) {
      // Handle error: Display error message or perform other actions

      if (error && error.response.data.errorCode === "EMAIL_ALREADY_EXIST") {
        let responseError = error.response.data;
        setErrorReviewByMajor(error.response);
        return responseError;
      }
    } finally {
      setLoadingPostReview(false);
    }
  };

  // Update localStorage whenever isGraduated state changes
  useEffect(() => {
    localStorage.setItem("isGraduated", isGraduated);
  }, [isGraduated]);

  // Function to handle state change
  const handleIsGraduated = (value) => {
    setIsGraduated(value);
  };

  // Update localStorage whenever isGraduated state changes
  useEffect(() => {
    localStorage.setItem("isProfessional", isProfessional);
  }, [isProfessional]);

  // Function to handle state change
  const handleIsProfessional = (value) => {
    setIsProfessional(value);
  };

  const handleSelectOption = (value, dropdownName) => {
    setFormData({ ...formData, [dropdownName]: value });
    setCurrentDropdown(null);
  };

  const handleLogin = () => {
    // Store the current path in session storage
    sessionStorage.setItem("redirectPath", window.location.pathname);

    // Redirect to the login page
    loginWithRedirect();
  };

  return (
    <div>
      <div className="container-form-major-review">
        <h6>
          Your honest feedback can shape the future for others. Share your
          experiences about your degree and career below to guide students and
          job seekers toward better majors and opportunities.
        </h6>
        <form>
          <div className="form-group-container">
            <label>Anonymous Review:</label>
            <p className="subtext">
              All reviews on RateMyMajor are anonymous. Your privacy matters to
              us, and we never share your personal information.
            </p>
            <div className="container-dark-mode">
              <DarkMode
                toggle={toggle}
                setToggle={setToggle}
                // switchToggle={switchToggle}
              />
              <div>
                <input
                  type="checkbox"
                  checked={formData.anonymous} // Use formData.anonymous directly
                  name="anonymous"
                  //onChange={handleChange} // Only need onChange event handler
                />
                <label>Yes</label>
              </div>
              {/* <div>
              <input
                type="checkbox"
                checked={!formData.anonymous}
                name="anonymous"
                value={!formData.anonymous}
                onChange={handleChange} // Only need onChange event handler
              />
              <label>No</label>
            </div> */}
            </div>
          </div>
          <div className="form-group-container">
            <label>Did you Graduate?</label>
            <p className="subtext">
              If you completed your education from a school, bootcamp, or
              self-training certification, select 'Yes' and proceed to fill out
              the rest of the form.
            </p>
            <div>
              <input
                type="radio"
                name="isGraduated"
                value="no"
                checked={isGraduated === "yes"}
                onChange={() => handleIsGraduated("yes")}
              />
              <label>Yes</label>
            </div>
            <div>
              <input
                type="radio"
                name="isGraduated"
                value="no"
                checked={isGraduated === "no"}
                onChange={() => handleIsGraduated("no")}
              />
              <label>No</label>
            </div>
          </div>
          {isGraduated === "yes" && (
            <div>
              <div className="form-group-container">
                <label>Major Studied</label>

                <p className="subtext">
                  What major did you study? If you come from self-taught
                  background or graduated from a bootcamp, please include the
                  name of the bootcamp or certifications in the form.
                </p>
                <div className="form-group" ref={dropdownContainerRefs.major}>
                  <RiArrowDropDownLine
                    className="icon-open-close rotated-in-zerodeg"
                    title="click to view all  majors on the dropdown"
                    onClick={(event) => handleToggleList("major", event)}
                  />
                  <input
                    className="form-control"
                    name="major"
                    onFocus={(event) => handleOnFocusList("major", event)}
                    value={formData.major}
                    onChange={handleChange}
                    placeholder="Select your  major or add new major, certifications or training "
                  ></input>

                  {formErrors.major && (
                    <span className="error">{formErrors.major}</span>
                  )}
                  <div
                    className={`form-group-list ${
                      currentDropdown === "major" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {majors.map((major) => (
                        <li
                          key={uuid()}
                          onClick={() => handleSelectOption(major, "major")}
                        >
                          {major}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <label>Year of Graduation:</label>
                <p className="subtext">
                  When did you complete your education or training?
                </p>
                <div
                  className="form-group"
                  ref={dropdownContainerRefs.graduationYear}
                >
                  <RiArrowDropDownLine
                    className="icon-open-close rotated-in-zerodeg"
                    title="click to view all  majors on the dropdown"
                    onClick={(event) =>
                      handleToggleList("graduationYear", event)
                    }
                  />
                  <input
                    className="form-control"
                    name="graduationYear"
                    onFocus={(event) =>
                      handleOnFocusList("graduationYear", event)
                    }
                    value={formData.graduationYear}
                    onChange={handleChange}
                    placeholder="Select your year of graduation or add new year "
                  ></input>
                  {formErrors.graduationYear && (
                    <span className="error">{formErrors.graduationYear}</span>
                  )}
                  <div
                    className={`form-group-list ${
                      currentDropdown === "graduationYear" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {years.map((year) => (
                        <li
                          key={uuid()}
                          onClick={() =>
                            handleSelectOption(year, "graduationYear")
                          }
                        >
                          {year}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="form-group-container">
                <label>School:</label>
                <p className="subtext">Which school did you graduate ?</p>
                <div className="form-group" ref={dropdownContainerRefs.school}>
                  <RiArrowDropDownLine
                    className="icon-open-close rotated-in-zerodeg"
                    title="click to view all  majors on the dropdown"
                    onClick={(event) =>
                      handleToggleList("graduationYear", event)
                    }
                  />
                  <input
                    className="form-control"
                    name="school"
                    onFocus={(event) => handleOnFocusList("school", event)}
                    value={formData.school}
                    onChange={handleChange}
                    placeholder="Select your year of graduation or add new year "
                  ></input>
                  {formErrors.school && (
                    <span className="error">{formErrors.school}</span>
                  )}
                  <div
                    className={`form-group-list ${
                      currentDropdown === "school" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {schools.map((school) => (
                        <li
                          key={uuid()}
                          onClick={() => handleSelectOption(school, "school")}
                        >
                          {school}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="form-group-container">
                <label>Country:</label>
                <p className="subtext">
                  Please select the country where you completed your education
                  or training. If you are a self-taught or graduated from a
                  bootcamp, please add it into the form accordingly.
                </p>
                <div className="form-group" ref={dropdownContainerRefs.country}>
                  <RiArrowDropDownLine
                    className="icon-open-close rotated-in-zerodeg"
                    title="click to view all countries on the dropdown"
                    onClick={(event) => handleToggleList("country", event)}
                  />
                  <input
                    className="form-control"
                    name="country"
                    onFocus={(event) => handleOnFocusList("country", event)}
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="Select country or add new country "
                  ></input>
                  {formErrors.country && (
                    <span className="error">{formErrors.country}</span>
                  )}
                  <div
                    className={`form-group-list ${
                      currentDropdown === "country" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {countries.map((country) => (
                        <li
                          key={uuid()}
                          onClick={() =>
                            handleSelectOption(country.name, "country")
                          }
                        >
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <label>Do you have a Professional Experience:</label>

                <p className="subtext">
                  Select 'Yes' or 'No' to indicate whether you have professional
                  experience.
                </p>
                <div>
                  <input
                    type="radio"
                    name="isProfessional"
                    value="no"
                    checked={isProfessional === "yes"}
                    onChange={() => handleIsProfessional("yes")}
                  />
                  <label>Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isProfessional"
                    value="no"
                    checked={isProfessional === "no"}
                    onChange={() => handleIsProfessional("no")}
                  />
                  <label>No</label>
                </div>
              </div>
              {isProfessional === "yes" && (
                <div>
                  <div className="form-group-container">
                    <label>Current Career:</label>
                    <p className="subtext">
                      What is your current career position or job title?
                    </p>
                    <div
                      className="form-group"
                      ref={dropdownContainerRefs.jobTitle}
                    >
                      <RiArrowDropDownLine
                        className="icon-open-close rotated-in-zerodeg"
                        title="click to view all  majors on the dropdown"
                        onClick={(event) => handleToggleList("jobTitle", event)}
                      />
                      <input
                        className="form-control"
                        name="jobTitle"
                        onFocus={(event) =>
                          handleOnFocusList("jobTitle", event)
                        }
                        value={formData.jobTitle}
                        onChange={handleChange}
                        placeholder="Select your job title or add new job title "
                      ></input>
                      {formErrors.jobTitle && (
                        <span className="error">{formErrors.jobTitle}</span>
                      )}
                      <div
                        className={`form-group-list ${
                          currentDropdown === "jobTitle" ? "show" : "hide"
                        }`}
                      >
                        <ul>
                          {positions.map((position) => (
                            <li
                              key={uuid()}
                              onClick={() =>
                                handleSelectOption(position, "jobTitle")
                              }
                            >
                              {position}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group-container">
                    <label>Employer/Industry:</label>
                    <p className="subtext">
                      Where do you work, and in which industry?
                    </p>
                    <div
                      className="form-group"
                      ref={dropdownContainerRefs.employer}
                    >
                      <RiArrowDropDownLine
                        className="icon-open-close rotated-in-zerodeg"
                        title="click to view all  majors on the dropdown"
                        onClick={(event) => handleToggleList("employer", event)}
                      />
                      <input
                        className="form-control"
                        name="employer"
                        onFocus={(event) =>
                          handleOnFocusList("employer", event)
                        }
                        value={formData.employer}
                        onChange={handleChange}
                        placeholder="Select your company   or add your company "
                      ></input>
                      {formErrors.employer && (
                        <span className="error">{formErrors.employer}</span>
                      )}
                      <div
                        className={`form-group-list ${
                          currentDropdown === "employer" ? "show" : "hide"
                        }`}
                      >
                        <ul>
                          {employers.map((employer) => (
                            <option
                              key={uuid()}
                              onClick={() =>
                                handleSelectOption(employer, "employer")
                              }
                            >
                              {employer}
                            </option>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group-container">
                    <label>Salary:</label>
                    <p className="subtext">
                      Please add a yearly salary based on your country
                    </p>
                    <div
                      className="form-group"
                      ref={dropdownContainerRefs.salary}
                    >
                      <RiArrowDropDownLine
                        className="icon-open-close rotated-in-zerodeg"
                        title="click to view all  majors on the dropdown"
                        onClick={(event) => handleToggleList("salary", event)}
                      />
                      <input
                        className="form-control"
                        name="salary"
                        onFocus={(event) => handleOnFocusList("salary", event)}
                        value={formData.salary}
                        onChange={handleChange}
                        placeholder="Select your salary or new salary "
                      ></input>
                      {formErrors.salary && (
                        <span className="error">{formErrors.salary}</span>
                      )}
                      <div
                        className={`form-group-list ${
                          currentDropdown === "salary" ? "show" : "hide"
                        }`}
                      >
                        <ul>
                          {salaries.map((salary) => (
                            <li
                              key={uuid()}
                              onClick={() =>
                                handleSelectOption(salary, "salary")
                              }
                            >
                              {salary}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group-container">
                    <label>Years of Experience:</label>
                    <p className="subtext">
                      Please select a range of experience from the list or add
                      years
                    </p>
                    <div
                      className="form-group"
                      ref={dropdownContainerRefs.experience}
                    >
                      <RiArrowDropDownLine
                        className="icon-open-close rotated-in-zerodeg"
                        title="click to view all  majors on the dropdown"
                        onClick={(event) =>
                          handleToggleList("experience", event)
                        }
                      />
                      <input
                        className="form-control"
                        name="experience"
                        onFocus={(event) =>
                          handleOnFocusList("experience", event)
                        }
                        value={formData.experience}
                        onChange={handleChange}
                        placeholder="Select years of experience or add experiencee "
                      ></input>
                      {formErrors.experience && (
                        <span className="error">{formErrors.experience}</span>
                      )}
                      <div
                        className={`form-group-list ${
                          currentDropdown === "experience" ? "show" : "hide"
                        }`}
                      >
                        <ul>
                          {yearsOfExperienceInTheField.map((experience) => (
                            <li
                              key={uuid()}
                              onClick={() =>
                                handleSelectOption(experience, "experience")
                              }
                            >
                              {experience}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isProfessional === "no" && (
                <p>
                  Since you have no professional experience , you're welcome to
                  continue with the remaining sections of the form.
                </p>
              )}
              <div className="form-group-container">
                <label>Advice for Students:</label>
                <p className="subtext">
                  What advice would you give to students considering this major?
                </p>
                <div className="form-group-container-textarea">
                  {" "}
                  <textarea
                    name="adviceForStudents"
                    value={formData.adviceForStudents}
                    onChange={handleChange}
                    placeholder="add your advice to students considering this major "
                  />
                </div>
                {formErrors.adviceForStudents && (
                  <span className="error">{formErrors.adviceForStudents}</span>
                )}
              </div>
              <div className="form-group-container">
                <label>Challenges Faced:</label>
                <p className="subtext">
                  What challenges did you face while studying this major or in
                  your career?
                </p>
                <div className="form-group-container-textarea">
                  <textarea
                    name="challengeFaced"
                    value={formData.challengeFaced}
                    onChange={handleChange}
                    placeholder="add challenges you have faced when taking this major "
                  />
                </div>
                {formErrors.challengeFaced && (
                  <span className="error">{formErrors.challengeFaced}</span>
                )}
              </div>
              {isProfessional === "yes" && (
                <div className="form-group-container">
                  <label>Opportunities and Advantages:</label>
                  <p className="subtext">
                    What special benefits did you gain from studying this major?
                    Also, where do you see yourself going with this major in
                    terms of future career opportunities? This could include
                    specific companies, cities, or industries.
                  </p>

                  <div className="form-group-container-textarea">
                    <textarea
                      name="opportunitiesAndAdvantages"
                      value={formData.opportunitiesAndAdvantages}
                      onChange={handleChange}
                      placeholder="optional"
                    />
                  </div>
                </div>
              )}
              <div className="form-group-container">
                <label>Major Recommendation</label>
                <p className="subtext">
                  How likely are you to recommend this major to other students?
                  <br />
                  Rate from 1 to 10: <br />
                  1 = Not likely <br />
                  10 = Very likely <br />
                  Please select a star to indicate your recommendation.
                </p>

                <div>
                  <Rating
                    setCurrentValue={setMajorRecommendationRating} // Use setRecommendRating for setCurrentValue
                    setHoverValue={(hover) =>
                      setMajorRecommendationRating((prev) => ({
                        ...prev,
                        hover,
                      }))
                    } // Update hover state directly
                    ratingUsers="100%"
                    rating="10"
                    hoverValue={majorRecommendationRating.hover}
                    currentValue={majorRecommendationRating.value}
                    name="majorRecommendation"
                  />
                </div>
                {formErrors.majorRecommendation && (
                  <span className="error">
                    {formErrors.majorRecommendation}
                  </span>
                )}
              </div>
              {isProfessional === "yes" && (
                <div className="form-group-container">
                  <label>Career Satisfaction</label>
                  <p className="subtext">
                    On a scale from 1 to 10, how satisfied are you with your
                    current career? <br />
                    1 represents the lowest satisfaction level <br />
                    10 represents the highest satisfaction level <br />
                    Please select a star to indicate your level of satisfaction.
                  </p>

                  <div style={{ textAlign: "left", margin: "0 !important" }}>
                    <Rating
                      setCurrentValue={setCareerSatisfactionRating} // Use setCareerSatisfactionRating for setCurrentValue
                      setHoverValue={(hover) =>
                        setCareerSatisfactionRating((prev) => ({
                          ...prev,
                          hover,
                        }))
                      } // Update hover state directly
                      ratingUsers="100%"
                      rating="10"
                      hoverValue={careerSatisfactionRating.hover}
                      currentValue={careerSatisfactionRating.value}
                      name="careerSatisfaction"
                    />
                  </div>
                  {formErrors.careerSatisfaction && (
                    <span className="error">
                      {formErrors.careerSatisfaction}
                    </span>
                  )}
                </div>
              )}
              <div className="form-group-container">
                <label>Level of difficulty</label>
                <p className="subtext">
                  On a scale of 1 to 10, how would you rate the difficulty of
                  this major?<br></br>1 represents the easiest major <br></br>
                  10 represents the most difficult <br></br>
                  Please select a star to indicate your rating.
                </p>
                <div>
                  <Rating
                    setCurrentValue={setLevelOfDifficultyRating} // Use setLevelOfDifficultyRating for setCurrentValue
                    setHoverValue={(hover) =>
                      setLevelOfDifficultyRating((prev) => ({ ...prev, hover }))
                    } // Update hover state directly
                    ratingUsers="100%"
                    rating="10"
                    hoverValue={levelOfDifficultyRating.hover}
                    currentValue={levelOfDifficultyRating.value}
                    name="levelOfdifficulty"
                  />
                </div>
                {formErrors.levelOfDifficulty && (
                  <span className="error">{formErrors.levelOfDifficulty}</span>
                )}
              </div>

              {isProfessional === "yes" && (
                <div className="form-group-container">
                  <label>Overall Experience with the Major and Workforce</label>
                  <p className="subtext">
                    How would you rate your combined experience of graduating
                    from this major and working in the industry? <br />
                    Rate from 1 to 10: <br />
                    1 = Poor experience <br />
                    10 = Excellent experience <br />
                    Please select a star to indicate your rating.
                  </p>
                  <div>
                    <Rating
                      setCurrentValue={setOverallRating} // Use setOverallRating for setCurrentValue
                      setHoverValue={(hover) =>
                        setOverallRating((prev) => ({ ...prev, hover }))
                      } // Update hover state directly
                      ratingUsers="100%"
                      rating="10"
                      hoverValue={overallRating.hover}
                      currentValue={overallRating.value}
                      name="overall"
                    />
                  </div>
                </div>
              )}
              <div className="form-group-container">
                <label>Additional Comments:</label>
                <p className="subtext">
                  Is there anything else you would like to share about your
                  educational and career journey
                </p>
                <div className="form-group-container-textarea">
                  <textarea
                    name="additionalComment"
                    value={formData.additionalComment}
                    onChange={handleChange}
                    placeholder="Feel free to share any additional tips or tricks not covered in the form"
                  />
                </div>
                {formErrors.levelOfDifficulty && (
                  <span className="error">{formErrors.levelOfDifficulty}</span>
                )}
              </div>
              <span onClick={handleModelSubmit} className="btn-primary">
                Submit Review
              </span>
            </div>
          )}
          {isGraduated === "no" && (
            <p>
              Apologies, but in order to leave a review, you must have completed
              education from a university, college, bootcamp, or obtained
              relevant certifications through personal self-training.
            </p>
          )}
          <div
            className={
              displayModelSubmit
                ? `full-window   spinner-show showPopUp ${(body.style.overflow =
                    "hidden")} `
                : `full-window  spinner-hide closePopUp ${(body.style.overflow =
                    "scroll")} `
            }
          >
            <div
              style={{
                background: "#fff",
                width: "fit-content",
                height: "fit-content",
                borderRadius: "10px",
                padding: "10px",
                fontWeight: "bold",
                maxWidth: "90%",
                textAlign: "center",
              }}
            >
              {Object.keys(formErrors).length > 0 && (
                <span className="error">
                  {Object.keys(formErrors).length === 1
                    ? "There is one error on the form.\nPlease review the form."
                    : `There are ${
                        Object.keys(formErrors).length
                      } errors on the form.\nPlease review the form.`}
                </span>
              )}

              <p style={{ color: "#111" }}>
                Ready to submit your review to the world🌍 ?
              </p>
              <div>
                <button className="btn-primary" onClick={handleSubmit}>
                  Yes
                </button>
                <button ref={closeButton} className="btn-primary">
                  No
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
        <div
          className={
            loadingPostReview
              ? ` full-window spinner-show showPopUp
                    `
              : `full-window spinner-hide`
          }
        >
          <Loading
            subtitle="Please wait ..."
            title={`Hey ${
              user && user.given_name
            }, we are uploading your review `}
          />
        </div>

        <div
          className={` full-window
          ${
            closeButtonModelSuccess
              ? "spinner-hide "
              : `spinner-show   
          `
          }
         ${
           !loadingPostReview &&
           !closeButtonModelSuccess &&
           createReviewByMajor.data != null
             ? `showPopUp`
             : `closePopup`
         }`}
        >
          <SuccessMessage
            user={user}
            closeButtonModelSuccess={closeButtonModelSuccess}
            handleCloseButtonSuccess={handleCloseButtonSuccess}
          />
        </div>
        <div
          className={` full-window 
           ${
             closeButtonModelError
               ? "spinner-hide "
               : `spinner-show 
           `
           } 
          ${
            !loadingPostReview &&
            !closeButtonModelError &&
            errorReviewByMajor.data != null &&
            createReviewByMajor.length === 0
              ? `showPopUp  `
              : `closePopup `
          }`}
        >
          <ErrorMessage
            user={user}
            closeButtonModelError={closeButtonModelError}
            handleCloseButtonError={handleCloseButtonError}
          />
        </div>
      </div>
      {Object.keys(formErrors).length > 0 && (
        <span className="error">
          {Object.keys(formErrors).length === 1
            ? "There is one error on the form. Please review the form."
            : `There are ${
                Object.keys(formErrors).length
              } errors on the form. Please review the form.`}
        </span>
      )}
    </div>
  );
};

export default MajorReviewForm;
