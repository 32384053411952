import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./review.scss";
import { StyleProduct, DifficultyLevel } from "../StyleProduct";
import Rating from "../Rating";
import StarRating from "../StarRating";
// import { getAllReviewsByMajor } from "../../utils/reviewByMajorService";
import MajorReviewForm from "../MajorReviewForm/MajorReviewForm";
import {
  convertHyphenToSpace,
  convertSpaceToHyphen,
} from "../../utils/RegExpUtil";
// import { images } from "../../storage/StorageService";

function Review(props) {
  const [difficult, setDifficult] = useState("");
  const [background, setBackground] = useState("");
  // const [error, setError] = useState();
  // const [reviewByMajor, setReviewByMajor] = useState([]);
  // const [loadingSingleMajor, setLoadingSingleMajor] = useState(false);

  const {
    major,
    school,
    overall,
    trend,
    careerSatisfaction,
    recommend,
    levelOfDifficulty,
    popularMajor,
    jobOpportunities,
    salary,
    imgUrl,
    reviews,
  } = props;
  useEffect(() => {
    setDifficult(categorizeMajorDifficulty(levelOfDifficulty));
    setBackground(getDifficultyBackgroundColor(levelOfDifficulty));
  }, [levelOfDifficulty]);

  // useEffect(() => {
  //   getAllReviewsByMajor(setReviewByMajor, setLoadingSingleMajor, major);
  // }, [major]);

  function categorizeMajorDifficulty(levelOfDifficulty) {
    if (levelOfDifficulty * 10 < 30) {
      return "Easy Major";
    } else if (levelOfDifficulty * 10 > 30 && levelOfDifficulty * 10 <= 60) {
      return "Average Difficulty";
    } else {
      return "Hard Major";
    }
  }

  function getDifficultyBackgroundColor(levelOfDifficulty) {
    if (levelOfDifficulty * 10 <= 30) {
      return "rgb(54, 230, 127)";
    } else if (levelOfDifficulty * 10 > 31 && levelOfDifficulty * 10 <= 60) {
      return "#ffc924"; // Light Yellow for Average Difficulty
    } else {
      return "#ff4742"; // Tomato Red for Hard Major
    }
  }
  const containerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imgUrl})`,
  };

  return (
    reviews.length != 0 && (
      // <Link to={`allreviews/${major && convertSpaceToHyphen(major)}`}>
      <article style={containerStyle} className="single-container-review">
        <p className=" single-container-review__major_title">
          {convertHyphenToSpace(major)}
        </p>
        {/* {type && (
      <div className="single-container-review_type">
        <span>{type}</span>
      </div>
    )} */}
        <div className="single-container-review_description_majors">
          <div className="single-container-review_description_majors__inner">
            <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                <span>🌟 </span>
                <p>
                  Overall about major <br /> and workforce
                </p>
              </div>
              <div className="single_book_first_row_second_item">
                <StyleProduct background={overall * 10}></StyleProduct>
              </div>
              <div className="single_book_first_row_third_item">
                <span>{(parseFloat(overall) * 10).toFixed(2)}%</span>
              </div>
            </div>
            <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                <span>📈</span>
                <p>Trends about jobs</p>
              </div>
              <div className="single_book_first_row_third_item">
                <span>{trend}</span>
              </div>
            </div>
            {/* <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                💼
                <p>Jobs opportunities</p>
              </div>
              <div className="single_book_first_row_second_item">
                <StyleProduct background={jobOpportunities * 10}></StyleProduct>
              </div>
              <div className="single_book_first_row_third_item">
                <span>{(parseFloat(jobOpportunities) * 10).toFixed(2)}%</span>
              </div>
            </div> */}

            <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                <span>🎓 </span>
                <p>major Recommendation</p>
              </div>
              <StarRating
                numberOfStars={5}
                rating={recommend}
                isFiveStars={true}
              />
              <div className="single_book_first_row_third_item">
                <span>{`${(parseFloat(recommend) * 10).toFixed(
                  2
                )}% recommend this major`}</span>
              </div>
            </div>
            <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                <span>🏆 </span>
                <p>Career Satisfaction</p>
              </div>

              <StarRating
                numberOfStars={5}
                rating={careerSatisfaction}
                isFiveStars={true}
              />
              <div className="single_book_first_row_third_item">
                <span>
                  {" "}
                  {`${(parseFloat(careerSatisfaction) * 10).toFixed(
                    2
                  )}% have job satisfaction`}
                </span>
              </div>
            </div>
            <div className="single_book_first_row">
              <div className="single_book_first_row_first_item">
                <span>📊</span>
                <p>Level Of Difficulty about the major</p>
              </div>
              <div className="single_book_first_row_second_item">
                <DifficultyLevel
                  background={levelOfDifficulty * 10}
                ></DifficultyLevel>
              </div>
              <div className="single_book_first_row_third_item">
                <span style={{ color: background }}>{difficult}</span>
              </div>
            </div>
            {/* <svg
                className="single-container-review_btnHide"
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 352 512"
                height="1.5em"
                width="1.5em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
              </svg> */}
            <Link
              style={{
                borderRadius: "4px",
                border: ".5px solid blue",
                padding: "3px",
                fontSize: "13px",
                width: "50%",
                display: "block",
                margin: "0 auto",
                color: "#fff",
              }}
              to={{
                pathname: `allreviews/${major && convertSpaceToHyphen(major)}`,
                state: {
                  imgUrl,
                },
              }}
            >
              Click to View insights about this Major and career opportunity.
            </Link>
          </div>
        </div>
      </article>
    )
  );
}

export default Review;
// &times
