import React from "react";
import NavBar from "../components/NavBar";
import Footer from "./Footer/Footer";

const Layout = ({ children, visible, handleVisible }) => {
  // const [visibility, setVisibility] = useState(false);

  // const handleVisibility = () => {
  //   setVisibility((prev) => {
  //     return !prev;
  //   });
  // };
  return (
    <>
      <NavBar visible={visible} handleVisible={handleVisible} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
