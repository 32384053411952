import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h4>Send Feedback</h4>
      <h6 style={{ marginBottom: "1rem" }} className="email-address">
        fonoandy@gmail.com
      </h6>
      <h6 className="office-address">Office Address:</h6>
      <address>13807 Jefferson Park, suite 8104 </address>
      <h4>Support team and Feedback</h4>
      <h6 className="email-address">fonoandy@gmail.com</h6>
    </div>
  );
};

export default ContactUs;
