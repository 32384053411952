import uuid from "react-uuid";
import defaultMajorImg from "../assets/img/coding_competition.jpg";

const defaultImage = {
  id: uuid(),
  name: "default",
  path: defaultMajorImg,
};

export default defaultImage;
