import React, { useState, useRef, useEffect } from "react";
import { FaYoutubeSquare } from "react-icons/fa";

import YouTube from "react-youtube";
import "./video.scss";

const Video = (props) => {
  const body = document.body;
  const closeButton = useRef();
  const videoRef = useRef();

  const { showPopUp, imageWindow, url } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleDisplay = () => {
    setIsOpen((prev) => !prev);
  };
  const handleClose = () => {
    videoRef.current.internalPlayer.pauseVideo();
    videoRef.current.internalPlayer.stopVideo();
  };
  useEffect(() => {
    if (isOpen) {
      closeButton.current.addEventListener("click", handleDisplay);
    }
    return () => {
      closeButton.current.removeEventListener("click", handleDisplay);
    };
  }, [isOpen]);

  return (
    <>
      <div onClick={handleDisplay} className="container-watch-video">
        <FaYoutubeSquare className="service-icon" />
        <p>Watch Video </p>
        <div className="container-watch-video-background-outer">
          <div className="container-watch-video-background-inner">
            <FaYoutubeSquare className="service-icon" />
            <p>Watch Video </p>
          </div>
        </div>
      </div>
      <div
        className={
          isOpen
            ? `${(body.style.overflow = "hidden")}`
            : `${(body.style.overflow = "scroll")}`
        }
      >
        <div
          className={
            isOpen
              ? ` ${imageWindow} container-video-ceo-show
                    `
              : `${imageWindow} container-video-ceo-hide  `
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="container-video">
              <YouTube
                ref={videoRef}
                videoId={url}
                className="Youtube-player scroll"
                width="100%"
                height="100%"
              />
            </div>

            <span
              onClick={handleClose}
              ref={closeButton}
              className="btn-danger"
            >
              &times;
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
