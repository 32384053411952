import React from "react";

const ImportantLinks = () => {
  return (
    <div>
      <h4>important links</h4>
      <ul>
        <li>
          <a href=""> Terms and Conditions</a>
        </li>
        <li>
          <a href=""> Disclamer</a>
        </li>
        <li>
          <a href=""> privacy</a>
        </li>
        <li>
          <a href=""> cooking policy</a>
        </li>
      </ul>
    </div>
  );
};

export default ImportantLinks;
