import React, { useEffect, useRef } from "react";
import { PiWarningCircleFill } from "react-icons/pi";
import "./errorMessage.scss";

function ErrorMessage({ user, handleCloseButtonError, closeButtonModelError }) {
  const closeButton = useRef();

  useEffect(() => {
    if (!closeButtonModelError) {
      console.log("gabriel", closeButtonModelError);
      closeButton.current.addEventListener("click", handleCloseButtonError);
    }
    return () => {
      closeButton.current.removeEventListener("click", handleCloseButtonError);
    };
  }, [closeButtonModelError]);
  return (
    <div className="outer-container-model-success-upload-review">
      <div className="inner-container-model-success-upload-review">
        <PiWarningCircleFill className="model-error-check-circle" />
        <div>
          <h3>Error</h3>
          {user && <span>{`Hey ${user.given_name},`}</span>}
          <p>
            We're sorry, but it seems you've already submitted a review with
            this email address. Thank you for your contribution!
          </p>
          <p>
            If you believe that someone else has submitted a review using your
            email address or you wish to update your review, please contact us
            at contact@ratemymajor.io
          </p>
        </div>
      </div>
      <span ref={closeButton} className="btn-danger-close">
        &times;
      </span>
    </div>
  );
}

export default ErrorMessage;
