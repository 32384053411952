import React from "react";
import "./share.scss";
import {
  FacebookButton,
  LinkedInButton,
  TwitterButton,
  RedditButton,
  PinterestButton,
} from "react-social";
import facebook from "../../assets/SociauMedia/facebook.jpg";
import twitter from "../../assets/SociauMedia/twitter.jpg";
import linkedin from "../../assets/SociauMedia/linkedin.jpg";
import reddit from "../../assets/SociauMedia/reddit.jpg";
import pinterest from "../../assets/SociauMedia/pinterest.png";

function Share({ description, slug }) {
  slug = "coding";

  let url = window.location.href;
  url = "https://ratemymajor.io";

  return (
    <div className="big-container-social-share">
      <p>{description}</p>
      <div className="container-social-share">
        <FacebookButton
          sharer={true}
          url={url}
          quote="best way to learn coding"
          appId={1359370807859748}
        >
          <div className="container-btn-share">
            <img src={facebook} title="share on Facebook" alt="Facebook logo" />
          </div>
        </FacebookButton>
        <TwitterButton url={url}>
          <div className="container-btn-share">
            <img src={twitter} title="share on Twitter" alt="Twitter logo" />
          </div>
        </TwitterButton>
        <LinkedInButton url={url}>
          <div className="container-btn-share">
            <img src={linkedin} title="share on Linkedin" alt="Linkedin logo" />
          </div>
        </LinkedInButton>
        <RedditButton url={url}>
          <div className="container-btn-share">
            <img src={reddit} title="share on Reddit" alt="Reddit logo" />
          </div>
        </RedditButton>
        <PinterestButton url={url}>
          <div className="container-btn-share ">
            <img
              src={pinterest}
              title="share on Pinterest"
              alt="Pinterest logo"
            />
          </div>
        </PinterestButton>
      </div>
    </div>
  );
}

export default Share;
