export function convertSpaceToHyphen(major) {
  // Convert to lowercase and remove leading/trailing spaces
  let formattedMajor = major.toLowerCase().trim();
  // Replace any sequence of spaces with a single hyphen
  formattedMajor = formattedMajor.replace(/\s+/g, "-");
  return formattedMajor;
}

export function convertHyphenToSpace(major) {
  // Replace hyphens with spaces
  major = major.replace(/-/g, " ");

  // Capitalize each word
  major = major.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  return major;
}
