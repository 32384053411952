import React from "react";
// import video from "../assets/video/background-video.mp4";
import video from "../assets/video/student_campus.mp4";

const Hero = ({ hero, children, videobackground, url }) => {
  const containerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})`,
  };
  return (
    <header style={containerStyle} className={hero}>
      {children}
      {videobackground && (
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            background:
              "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <source src={video} type="video/mp4" /> */}
          <source
            src="https://res.cloudinary.com/ground-service-international/video/upload/v1714876489/student_campus_jz9udu.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}
    </header>
  );
};
// Hero.defaultProps = {
//   hero: "defaultHero",
// };

export default Hero;
