import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { FaStar } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Title from "../../components/Title";
import uuid from "react-uuid";
import { BiSolidDownArrow } from "react-icons/bi";
import "./allreviews.scss";
import StarRating from "../../components/StarRating";
import { getAllReviewsByMajor } from "../../utils/reviewByMajorService";
import { FaShare } from "react-icons/fa";
import { SlLike } from "react-icons/sl";
import Loading from "../../components/Loading";
import Share from "../../constant/share/";
import { convertHyphenToSpace } from "../../utils/RegExpUtil";
import SvgUtil from "../../utils/SvgUtil";
import { useAuth0 } from "@auth0/auth0-react";
import DisplayCorrectFormat from "../../utils/DisplayCorrectFormat";
import defaultImg from "../../storage/StorageService";
// import Piechart from "../../components/Piechart";
import { withRouter } from "react-router-dom";

function AllReviews(props) {
  const [reviewByMajor, setReviewByMajor] = useState([]);
  const [reviewByMajorFilter, setReviewByMajorFilter] = useState([]);
  const [loadingSingleMajor, setLoadingSingleMajor] = useState(true);
  const [readMoreAdvice, setReadMoreAdvice] = useState({});
  const [readMoreAdditional, setReadMoreAdditional] = useState({});
  const [readMoreChallenge, setReadMoreChallenge] = useState({});
  const [readMoreOpportunity, setReadMoreOpportunity] = useState({});
  const [path, setPath] = useState("");
  const [averageRating, setAverageRating] = useState(undefined);

  const [averages, setAverages] = useState({
    overall: 0,
    recommend: 0,
    careerSatisfaction: 0,
    levelOfDifficulty: 0,
    jobOpportunities: 0,
  });

  const { isAuthenticated, logout, user } = useAuth0();

  // Initialize readMoreAdvice with all keys set to false
  useEffect(() => {
    const initialReadMoreAdvice = {};
    // Initialize all keys to false
    reviewByMajor.forEach((_, index) => {
      initialReadMoreAdvice[index] = false;
    });
    setReadMoreAdvice(initialReadMoreAdvice);
  }, [reviewByMajor]);

  // Initialize readMoreAdditional with all keys set to false
  useEffect(() => {
    const initialReadMoreAdditional = {};
    // Initialize all keys to false
    reviewByMajor.forEach((_, index) => {
      initialReadMoreAdditional[index] = false;
    });
    setReadMoreAdditional(initialReadMoreAdditional);
  }, [reviewByMajor]);

  // Initialize readMoreChallenge with all keys set to false
  useEffect(() => {
    const initialMoreChallenge = {};
    // Initialize all keys to false
    reviewByMajor.forEach((_, index) => {
      initialMoreChallenge[index] = false;
    });
    setReadMoreChallenge(initialMoreChallenge);
  }, [reviewByMajor]);

  // Initialize readMoreOpportunity with all keys set to false
  useEffect(() => {
    const initialReadMoreOpportunity = {};
    // Initialize all keys to false
    reviewByMajor.forEach((_, index) => {
      initialReadMoreOpportunity[index] = false;
    });
    setReadMoreOpportunity(initialReadMoreOpportunity);
  }, [reviewByMajor]);

  const toggleReadMore = (category, index) => {
    switch (category) {
      case "Advice":
        setReadMoreAdvice((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
        break;
      case "Challenge":
        setReadMoreChallenge((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
        break;
      case "Opportunity":
        setReadMoreOpportunity((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
        break;
      case "Additional":
        setReadMoreAdditional((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
        break;
      default:
        break;
    }
  };

  const slug = props.match.params.slug;
  console.log(slug);
  const imgUrl = props.location.state.imgUrl;
  console.log(imgUrl);

  useEffect(() => {
    getAllReviewsByMajor(setReviewByMajor, setLoadingSingleMajor, slug);
  }, []);

  useEffect(() => {
    // Filter reviews where `pending` is "true" (assuming `pending` is a string)
    const filterReview = reviewByMajor.filter(
      (review) => review.pending !== "true"
    );
    // Update the state with filtered reviews
    setReviewByMajorFilter(filterReview);
  }, [reviewByMajor]); // Dependency array ensures this runs when `reviewByMajor` changes

  useEffect(() => {
    const calculateAverage = (category) => {
      let sum = 0;
      let count = 0;

      reviewByMajor.forEach((major) => {
        const value = parseFloat(major[category]);
        if (!isNaN(value)) {
          // Check if value is a valid number
          sum += value;
          count++;
        }
      });

      return count === 0 ? 0 : sum / count;
    };

    // Calculate averages
    const averageOverall = calculateAverage("overall");
    const averageRecommend = calculateAverage("majorRecommendation");
    const averageCareerSatisfaction = calculateAverage("careerSatisfaction");
    const averageLevelOfDifficulty = calculateAverage("levelOfDifficulty");
    // const averageJobOpportunities =
    //   calculateAverage("jobOpportunities") === 0
    //     ? 4
    //     : calculateAverage("jobOpportunities");

    // Update state with the averages
    setAverages({
      overall: averageOverall,
      recommend: averageRecommend,
      careerSatisfaction: averageCareerSatisfaction,
      levelOfDifficulty: averageLevelOfDifficulty,
      // jobOpportunities: averageJobOpportunities,
    });
  }, [reviewByMajor]);

  useEffect(() => {
    console.log("averages", averages);
  }, [averages]);

  const noResultsEmojis = ["😐", "😶", "🤔", "🧐"];
  const randomNoResultEmoji = useMemo(
    () => noResultsEmojis[Math.floor(Math.random() * noResultsEmojis.length)],
    [reviewByMajor]
  );

  useEffect(() => {
    // Count how many values are non-zero
    let nonZeroCount = 0;
    let sum = 0;

    if (averages.overall !== 0) {
      sum += parseInt(averages.overall);
      nonZeroCount++;
    }
    if (averages.careerSatisfaction !== 0) {
      sum += parseInt(averages.careerSatisfaction);
      nonZeroCount++;
    }
    if (averages.levelOfDifficulty !== 0) {
      sum += parseInt(averages.levelOfDifficulty);
      nonZeroCount++;
    }
    if (averages.recommend !== 0) {
      sum += parseInt(averages.recommend);
      nonZeroCount++;
    }

    // Check if at least one value is non-zero to avoid division by zero
    if (nonZeroCount > 0) {
      // Calculate the average rating based on the number of non-zero values
      const averageRating = sum / nonZeroCount;
      // Update the state with the calculated average rating
      setAverageRating(averageRating);
    } else {
      // If all values are zero, set the average rating to 0
      setAverageRating(0);
    }
  }, [
    averages.overall,
    averages.careerSatisfaction,
    averages.levelOfDifficulty,
    averages.recommend,
  ]);

  function colorRating(rating) {
    if (rating >= 7) {
      return "#32CD32"; // green
    } else if (rating <= 4) {
      return "#FF0000"; // red
    } else {
      return "#FFBA5A"; // yellow
    }
  }

  const howManyYearsOfExperience = (dropdownexperience) => {
    switch (dropdownexperience) {
      case "0 - 1":
        return 1;
      case "2 - 3":
        return 2;
      case "3 - 6":
        return 2;
      case "8 - 10":
        return 8;
      case "10 - 15":
        return 10;
      case "15 - 18":
        return 12;
      case "18 - 20":
        return 16;
      default:
        return dropdownexperience; // or any default value you prefer
    }
  };

  return (
    <Layout>
      <Hero
        url={imgUrl != null ? imgUrl : defaultImg.path}
        hero="defaultReview"
      >
        <Banner
          title={`${convertHyphenToSpace(slug)} Reviews and career insights`}
          //           subtitle="Access all reviews for computer science"
        ></Banner>
      </Hero>
      <section className="section-jobs">
        <Title
          title={`Read All reviews about ${convertHyphenToSpace(
            slug
          )} and career insights.`}
          specificTitle
        />
        <div>
          <p style={{ textAlign: "center", marginBottom: "2rem" }}>
            Take time to read all the reviews and please add anything possible
          </p>
          <div className="container-share-social">
            <h4>Please share these reviews on social :</h4>
            <div>
              <Share />
            </div>
          </div>
          <div className="container-overall-rating">
            <h4 className="descriptionOverallRating">Average rating :</h4>
            {/* <FaStar style={{ color: "#FFBA5A", fontSize: "50px" }} /> */}
            <div className="container-star-rating">
              <FaStar
                style={{ color: colorRating(averageRating), fontSize: "50px" }}
              />

              <span
                style={{ fontWeight: "800", color: "gray", fontSize: "30px" }}
              >
                {averageRating} out of 10
              </span>
            </div>
            <Link
              className="btn-primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginLeft: "5px",
                width: "fit-content",
                textAlign: "center !important",
              }}
              to="/reviews/add-new-review"
            >
              <IoIosAdd style={{ display: "block", fontSize: "25px" }} />
              <span>Add Review</span>
              <span>🌟</span>
            </Link>
          </div>
        </div>
        {/* <div style={{ display: "flex", marginTop: "2rem" }}> */}
        {/* <div>
            <h4>Average rating by category :</h4>
            <FaStar style={{ color: "#FFBA5A", fontSize: "50px" }} />
            <span
              style={{ fontWeight: "800", color: "gray", fontSize: "40px" }}
            >
              {averageRating} out of 10
            </span>
            <Piechart averages={averages} />
          </div> */}

        <div>
          {loadingSingleMajor && (
            <Loading
              title="fetching all reviews for this major"
              subtitle="Please wait ..."
            />
          )}

          {reviewByMajorFilter.length === 0 && !loadingSingleMajor && (
            <div style={{ textAlign: "center" }}>
              <span style={{ fontSize: "4rem" }}>{randomNoResultEmoji}</span>
              <p>No Reviews Found for this major</p>
              <span>Please be the first to add your review</span>
            </div>
          )}
          {/* {reviewByMajor.length === 0 &&
            pending == "true" &&
            !loadingSingleMajor && (
              <div style={{ textAlign: "center" }}>
                <span style={{ fontSize: "4rem" }}>{randomNoResultEmoji}</span>
                <p>No Reviews Found for this major</p>
                <span>Please be the first to add your review</span>
              </div>
            )} */}
          {reviewByMajorFilter.map((data, index) => {
            let {
              additionalComment,
              adviceForStudents,
              school,
              anonymous,
              careerSatisfaction,
              challengeFaced,
              country,
              email,
              employer,
              firstName,
              graduationYear,
              id,
              jobTitle,
              lastName,
              levelOfDifficulty,
              major,
              majorRecommendation,
              opportunitiesAndAdvantages,
              overall,
              pending,
              reviewDate,
              salary,
              timeStamp,
              yearsOfExperience,
              currency,
            } = data;

            return (
              <div key={uuid()} className="container-wrapper-all-reviews">
                <div className="container-inner-all-reviews">
                  <div className="container-inner-all-reviews_first_row">
                    <div>
                      <div className="container-img-title-comp">
                        <div className="container-img-title-comp_first_row">
                          <div className="container-img-title-comp_first_row_image">
                            {/* {isAuthenticated ? (
                          <img
                            src="https://lh3.googleusercontent.com/a/ACg8ocJMd_RQs2Igmksqr08AMap0Hzn1Pdvtd-n7TEwJN2YuVA=s96-c"
                            alt={user.given_name}
                          />
                        ) : (
                          <SvgUtil />
                        )} */}
                            <SvgUtil />
                          </div>
                          {employer && (
                            <div
                              className="firstRowTitleDescription"
                              style={{ marginLeft: "10px" }}
                            >
                              <h5>{jobTitle}</h5>
                              <span>At {employer}</span>
                            </div>
                          )}
                        </div>
                        {salary && (
                          <div className="firstRowTitleDescription">
                            <h5>Base Salary</h5>
                            <p>Stock , Equity not include </p>
                            <span
                              style={{
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >{`${salary} ${currency}/Year`}</span>
                          </div>
                        )}
                        {yearsOfExperience && (
                          <div
                            className="firstRowTitleDescription"
                            style={{ textAlign: "center" }}
                          >
                            <h5>Years of Experience</h5>
                            <span style={{ fontWeight: "bold" }}>
                              {!Number.isInteger(
                                parseInt(
                                  howManyYearsOfExperience(yearsOfExperience)
                                )
                              ) > 1
                                ? `${yearsOfExperience} Years`
                                : parseInt(yearsOfExperience) > 1
                                ? `${yearsOfExperience} Years`
                                : `${yearsOfExperience} Year`}
                            </span>
                          </div>
                        )}

                        <div
                          className="firstRowTitleDescription"
                          style={{ textAlign: "center" }}
                        >
                          <h5>Date Submitted</h5>
                          <span>{reviewDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-inner-all-reviews_second_row">
                    <div>
                      <h4>Subratings</h4>
                      {parseInt(overall) > 0 && (
                        <div className="container-inner-all-reviews_second_row_career">
                          <span>Overall about the Major and Workforce</span>
                          <StarRating
                            numberOfStars={10}
                            rating={overall}
                            isFiveStars={false}
                          />
                        </div>
                      )}
                      {parseInt(careerSatisfaction) > 0 && (
                        <div className="container-inner-all-reviews_second_row_career">
                          <span>
                            Career Satisfaction ( Work Life Balance ){" "}
                          </span>
                          <StarRating
                            numberOfStars={10}
                            rating={careerSatisfaction}
                            isFiveStars={false}
                          />
                        </div>
                      )}
                      <div className="container-inner-all-reviews_second_row_career">
                        <span>Major Recommendation</span>
                        <StarRating
                          numberOfStars={10}
                          rating={majorRecommendation}
                          isFiveStars={false}
                        />
                      </div>
                      <div className="container-inner-all-reviews_second_row_career">
                        <span>Level of Difficulty about this major </span>
                        <StarRating
                          numberOfStars={10}
                          rating={levelOfDifficulty}
                          isFiveStars={false}
                        />
                      </div>

                      {school && (
                        <div className="container-inner-all-reviews_second_row_career">
                          <span>School </span>
                          <span
                            style={{
                              color: "rgb(255, 186, 90)",
                              margin: "1rem 0",
                            }}
                          >
                            (school)
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="container-inner-all-reviews_third_row">
                    <div className="container-inner-all-reviews_third_row_MGC">
                      <div className="thirdRowTitleDescription">
                        <h5>Major Studied</h5>
                        <span>{convertHyphenToSpace(slug)}</span>
                      </div>

                      <div className="thirdRowTitleDescription">
                        <h5>Graduation year</h5>
                        <span>{graduationYear}</span>
                      </div>
                      <div className="thirdRowTitleDescription">
                        <h5>Country</h5>
                        <span>{country}</span>
                      </div>
                    </div>
                  </div>
                  {adviceForStudents && (
                    <div className="container-inner-all-reviews_fourth_row">
                      <div>
                        <h4>Advice for Students</h4>
                        <p
                          style={{ whiteSpace: "pre-wrap" }}
                          className={
                            readMoreAdvice[index]
                              ? "click-read-more"
                              : "click-read-less"
                          }
                        >
                          {adviceForStudents}
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleReadMore("Advice", index)}
                        >
                          <span
                            style={{
                              color: "rgba(92,171,223,1)", // Set color to rgba(92,171,223,1)
                              fontSize: "15px",
                              fontWeight: "800", // Corrected typo from fontWeigth to fontWeight
                              textDecoration: "underline",
                            }}
                          >
                            {adviceForStudents.length >= 300 &&
                              (readMoreAdvice[index]
                                ? " read less"
                                : " read more")}
                          </span>
                          {adviceForStudents.length >= 300 && (
                            <BiSolidDownArrow
                              style={{
                                color: "rgba(92,171,223,1)",
                                marginLeft: "10px",
                                transform: `${
                                  readMoreAdvice[index]
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)"
                                }`,
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {challengeFaced && (
                    <div className="container-inner-all-reviews_fifth_row">
                      <div>
                        <h4>Challenges faced</h4>
                        <p
                          className={
                            readMoreChallenge[index]
                              ? "click-read-more"
                              : "click-read-less"
                          }
                        >
                          {/* {challengeFaced} */}
                          <DisplayCorrectFormat text={challengeFaced} />
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleReadMore("Challenge", index)}
                        >
                          <span
                            style={{
                              color: "rgba(92,171,223,1)", // Set color to rgba(92,171,223,1)
                              fontSize: "15px",
                              fontWeight: "800", // Corrected typo from fontWeigth to fontWeight
                              textDecoration: "underline",
                            }}
                          >
                            {challengeFaced.length >= 100 && // Wrap the condition in curly braces
                              (readMoreChallenge[index]
                                ? " read less"
                                : " read more")}
                          </span>
                          {challengeFaced.length >= 100 && (
                            <BiSolidDownArrow
                              style={{
                                color: "rgba(92,171,223,1)",
                                marginLeft: "10px",
                                transform: `${
                                  readMoreChallenge[index]
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)"
                                }`,
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {opportunitiesAndAdvantages && (
                    <div className="container-inner-all-reviews_sixth_row">
                      <div>
                        <h4>Opportunity beyond and advantages</h4>
                        <p
                          className={
                            readMoreOpportunity[index]
                              ? "click-read-more"
                              : "click-read-less"
                          }
                        >
                          {opportunitiesAndAdvantages}
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleReadMore("Opportunity", index)}
                        >
                          <span
                            style={{
                              color: "rgba(92,171,223,1)", // Set color to rgba(92,171,223,1)
                              fontSize: "15px",
                              fontWeight: "800", // Corrected typo from fontWeigth to fontWeight
                              textDecoration: "underline",
                            }}
                          >
                            {opportunitiesAndAdvantages.length >= 100 &&
                              (readMoreOpportunity[index]
                                ? " read less"
                                : " read more")}
                          </span>
                          {opportunitiesAndAdvantages.length >= 100 && (
                            <BiSolidDownArrow
                              style={{
                                color: "rgba(92,171,223,1)",
                                marginLeft: "10px",
                                transform: `${
                                  readMoreOpportunity[index]
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)"
                                }`,
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {additionalComment && (
                    <div className="container-inner-all-reviews_seventh_row">
                      <div>
                        <h4>additionals comments</h4>
                        <p
                          className={
                            readMoreAdditional[index]
                              ? "click-read-more"
                              : "click-read-less"
                          }
                        >
                          {additionalComment}
                        </p>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleReadMore("Additional", index)}
                        >
                          <span
                            style={{
                              color: "rgba(92,171,223,1)", // Set color to rgba(92,171,223,1)
                              fontSize: "15px",
                              fontWeight: "800", // Corrected typo from fontWeigth to fontWeight
                              textDecoration: "underline",
                            }}
                          >
                            {additionalComment.length >= 100 &&
                              (readMoreAdditional[index]
                                ? " read less"
                                : " read more")}
                          </span>
                          {additionalComment.length >= 100 && (
                            <BiSolidDownArrow
                              style={{
                                color: "rgba(92,171,223,1)",
                                marginLeft: "10px",
                                transform: `${
                                  readMoreAdditional[index]
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)"
                                }`,
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

export default withRouter(AllReviews);
